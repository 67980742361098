import React, { useState } from 'react';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com'; // Importer EmailJS
import { Card, CardContent } from '../../components/ui/card';
import { Phone, Mail } from 'lucide-react'; // Importer les icônes depuis Lucide React
import heroImage from '../PWAPage/3.jpg';  // Image de fond
import logoImage from '../PWAPage/192x192.png';  // Logo de Vytar
import countries from './countries';
import Flag from 'react-world-flags';
import OptimizedImage from "../../components/OptimizedImage"; // Assurez-vous que le chemin est correct
import NavigationBar from "../../components/NavigationBar";



const CustomSelect = ({ countries, value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectedCountry = countries.find(c => c.dial_code === value);

    return (
        <div className="relative">
            <div
                className="flex items-center w-auto px-3 py-2 bg-white bg-opacity-20 text-black rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
            >
                <Flag code={selectedCountry.code} className="inline-block mr-2" width="20" />
                <span className="text-white">{`${selectedCountry.code} (${selectedCountry.dial_code})`}</span>
            </div>
            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white shadow-lg max-h-60 overflow-auto rounded-md">
                    {countries.map((country, index) => (
                        <div
                            key={`${country.code}-${index}`}
                            className="flex items-center px-3 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                                onChange(country.dial_code);
                                setIsOpen(false);
                            }}
                        >
                            <Flag code={country.code} className="inline-block mr-2" width="20" />
                            <span className="text-black">{`${country.code} (${country.dial_code})`}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};




const ContactPage = () => {

    const [formData, setFormData] = useState({ name: '', email: '', country: '+237', phone: '', message: '' });

    // Validation du numéro de téléphone : seulement les chiffres et au moins 8 caractères
    const isValidPhoneNumber = (phone) => /^[0-9]{8,}$/.test(phone);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isValidPhoneNumber(formData.phone)) {
            alert("Veuillez entrer un numéro de téléphone valide (au moins 8 chiffres).");
            return;
        }

        const fullPhoneNumber = formData.country + formData.phone;

        // Utiliser EmailJS pour envoyer le message
        emailjs.send(
            'service_wf8b0b7', // Service ID
            'template_nl5pmzd', // Template ID
            {
                name: formData.name,
                email: formData.email,
                phone: fullPhoneNumber,
                message: formData.message,
            },
            'RzsTmJDpCvaXz-OqO' // Public Key
        )
            .then((response) => {
                alert('Message envoyé avec succès!');
                setFormData({ name: '', email: '', country: '+237', phone: '', message: '' });
            })
            .catch((error) => {
                console.error('Erreur:', error);
                alert('Une erreur est survenue lors de l\'envoi du message.');
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="relative min-h-screen pb-32">
            <NavigationBar />
            {/* Image de fond */}
            <div className="absolute inset-0 z-0">
                <OptimizedImage
                    src={heroImage}  // Utilise l'image importée
                    alt="Background"
                    className="absolute inset-0 w-full h-full object-cover"
                    sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 100vw"
                    style={{ display: 'block', width: '100%', height: '100%' }}
                />
                <div className="absolute inset-0 bg-black opacity-40"></div> {/* Overlay */}
            </div>

            {/* Contenu principal */}
            <div className="relative z-10 container mx-auto px-4 py-8 text-white flex flex-col lg:flex-row items-center justify-center min-h-screen">

                {/* Logo et slogan */}
                <div className="flex flex-col items-center mb-8 lg:mb-0">
                    <img src={logoImage} alt="Logo Vytar" className="w-32 h-32 mb-4" />
                    <h1 className="text-3xl font-bold">une vision smart</h1>
                </div>

                {/* Ligne noire de séparation en blanc */}
                <div className="hidden lg:block border-l-2 border-white mx-8 h-96"></div>


                {/* Formulaire de contact */}
                <div className="max-w-lg w-full">
                    <Card className="bg-opacity-30 backdrop-filter backdrop-blur-lg">
                        <CardContent>
                            <h2 className="text-3xl font-bold text-center mb-6 mt-6">Vos Commentaires</h2>
                            <form className="space-y-4" onSubmit={handleSubmit}>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.1 }}
                                >
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        placeholder="Votre nom"
                                        className="w-full px-3 py-2 bg-white bg-opacity-20 text-white placeholder-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                    />
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder="Votre email"
                                        className="w-full px-3 py-2 bg-white bg-opacity-20 text-white placeholder-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                    />
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.3 }}
                                >
                                    {/* Sélection du pays et champ pour le numéro de téléphone */}
                                    <div className="flex space-x-2 items-center w-full">

                                        <div className="flex-shrink-0">
                                            <CustomSelect
                                                countries={countries}
                                                value={formData.country}
                                                onChange={(value) => {
                                                    const selectedCountry = countries.find(c => c.dial_code === value);
                                                    setFormData({
                                                        ...formData,
                                                        country: value,
                                                        countryCode: selectedCountry.code
                                                    });
                                                }}
                                            />
                                        </div>


                                        <input
                                            type="tel"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            placeholder="Votre numéro de téléphone"
                                            className="flex-grow px-3 py-2 bg-white bg-opacity-20 text-white placeholder-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            required
                                        />
                                    </div>

                                </motion.div>
                                <motion.div
                                    initial={{opacity: 0, y: 20}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: 0.4}}
                                >
                                    <textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        placeholder="Votre message"
                                        rows="4"
                                        className="w-full px-3 py-2 bg-white bg-opacity-20 text-white placeholder-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    ></textarea>
                                </motion.div>
                                <motion.div
                                    initial={{opacity: 0, y: 20}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: 0.5}}
                                >
                                    <button
                                        type="submit"
                                        className="w-full px-4 py-2 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg text-white font-bold rounded-md hover:bg-opacity-50 transition duration-300"
                                        style={{ backgroundColor: '#4285F4' }}  // Couleur personnalisée bleue
                                    >
                                        Envoyez
                                    </button>

                                </motion.div>
                            </form>
                        </CardContent>
                    </Card>
                </div>

                {/* Coordonnées */}
                <div className="flex flex-col items-center justify-center lg:justify-start lg:ml-8 mt-8 lg:mt-0">
                    <motion.div
                        className="text-center space-y-4"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.6 }}
                    >
                        <div
                            className="flex items-center space-x-4 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg p-2 rounded-md">
                            <Phone className="w-8 h-8 text-white"/> {/* Changer l'icône du téléphone en blanc */}
                            <a href="tel:+237695092273" className="text-xl text-white font-semibold">+237 695 092
                                273</a> {/* Numéro de téléphone en blanc */}
                        </div>

                        <div
                            className="flex items-center space-x-4 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg p-2 rounded-md">
                            <Mail className="w-8 h-8 text-white"/> {/* Changer l'icône d'email en blanc */}
                            <a href="mailto:tsafack40@gmail.com"
                               className="text-xl text-white font-semibold">tsafack40@gmail.com</a> {/* Email en blanc */}
                        </div>

                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
