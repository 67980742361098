import React, {useEffect, useRef, useState} from 'react';
import { motion, useAnimation } from 'framer-motion';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaLinkedin } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import heroImage from '../PWAPage/2.jpg';
import profil1 from './profil1.png';
import OptimizedImage from '../../components/OptimizedImage';
import NavigationBar from "../../components/NavigationBar";
import AnimatedBackground from './AnimatedBackground';
import DynamicSeparator from './DynamicSeparator';
import Skills3D from './Skills3D';

// Import des icônes de technologies
import htmlIcon from '../PWAPage/HTML.png';
import cssIcon from '../PWAPage/tailwindcss.png';
import jsIcon from '../PWAPage/javascript.png';
import pythonIcon from '../PWAPage/python.png';
import djangoIcon from '../PWAPage/django.png';
import postgresIcon from '../PWAPage/postgresql.png';
import reactImage from '../PWAPage/react.png';
import flask from '../PWAPage/img.png';
import * as THREE from "three";
import DynamicBackground from "./DynamicBackground";



// Données du CV
const cvData = {
    contact: {
        email: { icon: FaEnvelope, data: "tsafack40@gmail.com", label: 'Email', href: 'mailto:tsafack40@gmail.com' },
        phone: { icon: FaPhone, data: "+237 695-092-273", label: 'Téléphone', href: 'tel:+237695092273' },
        location: { icon: FaMapMarkerAlt, data: "Yaoundé, Cameroun", label: 'Localisation' },
        /*linkedin: { icon: FaLinkedin, data: "LinkedIn", label: 'LinkedIn', href: '[Votre profil LinkedIn]' }*/
    },

    summary: "Je suis un développeur web full-stack avec une solide expérience en télématique et traitement de données, " +
        "particulièrement spécialisé dans le back-end avec Django et le front-end avec React. Maîtrisant Python, JavaScript, " +
        "HTML et Tailwind CSS, je crée des interfaces utilisateur réactives et des systèmes robustes côté serveur." +
        " Fort de mes compétences en collecte et analyse de données, je développe des solutions performantes pour des systèmes de suivi GPS, " +
        "l'optimisation de flotte et le traitement de Big Data.",

    experience: [
        {
            title: "Développeur Web spécialisé en tracking",
            company: "DONSY Sarl",
            period: "01/2021 - présent",
            responsibilities: [
                "Développement d'applications web pour le tracking GPS",
                "Analyse de données et optimisation de gestion de flotte",
                "Conception et développement de systèmes de collecte et de stockage de données pour les balises GPS",
                "Développement d'algorithmes de traitement de Big data",
                "Mise en place de systèmes d'analyse prédictive",
                "Conception et développement de rapports personnalisés",
                "Intégration de la plateforme de suivi dans des applications tierces"
            ]
        },
        {
            title: "Responsable Technique en télématique",
            company: "Neuraune Sarl",
            period: "06/2020 - 12/2020",
            responsibilities: [
                "Installation de kits GPS",
                "Monitoring de flotte",
                "Diagnostics de systèmes de Tracking",
                "Production de documentation technique"
            ]
        }
    ],

    hardSkills: {
        backEnd: [
            { icon: djangoIcon, name: "Django", description: "Création d'API REST, gestion des bases de données, intégration de services tiers" },
            { icon: pythonIcon, name: "Python", description: "Programmation orientée objet, automatisation de tâches, scripts de traitement de données" },
            { icon: postgresIcon, name: "PostgreSQL", description: "Gestion de bases de données" },
            { icon: flask, name: "flask", description: "Création d'API REST pour des applications légères" }
        ],
        frontEnd: [
            { icon: reactImage, name: "React", description: "Développement d'interfaces utilisateur dynamiques, gestion de l'état avec Redux, création de composants réutilisables" },
            { icon: jsIcon, name: "JavaScript", description: "Manipulation du DOM, utilisation des API, développement de fonctionnalités interactives" },
            { icon: htmlIcon, name: "HTML", description: "Création de pages web modernes et responsive" },
            { icon: cssIcon, name: "Tailwind CSS", description: "Intégration de designs personnalisés" }
        ],
        other: [
            "Télématique (manipulation de balises GPS)",
            "SolidWorks (Conception 3D)",
            "Proteus (conception de circuits électriques/électroniques)",
            "Matlab et Maple (modélisation cinématique des robots)"
        ]
    },

    softSkills: [
        "Travail d'équipe",
        "Communication efficace",
        "Résolution de problèmes",
        "Adaptabilité"
    ],

    education: [
        {
            degree: "Licence en Mathématique",
            institution: "Université de Yaoundé I, Yaoundé (Cameroun)",
            year: 2021
        },
        {
            degree: "Certification de Robotique",
            institution: "Strasbourg (France)",
            year: 2021,
            certificateLink: "https://www.rbotx.org/certification/laur%C3%A9ats#h.qtmy9e1ibaov"
        },
        {
            degree: "Certificat développeur web",
            institution: "DONSYL",
            year: 2020
        },
        {
            degree: "Baccalauréat, série scientifique (C)",
            institution: "Lycée Général Leclerc, Yaoundé (Cameroun)",
            year: 2015
        }
    ],

    languages: [
        { language: "Français", level: 4.5 },
        { language: "Anglais", level: 2.5 }
    ]
};

const CVPage = () => {
    const contentRef = useRef(null);

    const handleAboutClick = () => {
        contentRef.current?.scrollIntoView({ behavior: 'smooth' });
    };






    return (
        <div className="bg-white text-gray-800 min-h-screen pb-32">
            <AnimatedBackground />
            <div className="relative z-10">
                <NavigationBar/>

                {/* Hero Section - Kept as original */}
                <section className="min-h-screen flex flex-col items-center justify-center py-10 px-4">
                    <div className="w-full max-w-4xl mx-auto grid grid-cols-1 gap-8 items-center">
                        <div className="w-auto h-auto max-w-[252px] max-h-[292px] mx-auto">
                            <OptimizedImage
                                src={profil1}
                                alt="Profile"
                                className="w-full h-full object-contain"
                                sizes="252px"
                            />
                        </div>

                        <div className="text-center">
                            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-2">
                                Kevin Hulrich Tsafack
                            </h1>
                            <h2 className="text-xl sm:text-2xl text-white mb-2" >
                                Développeur Web Full-Stack
                            </h2>

                            <button
                                className="mt-8 px-6 py-2 bg-white text-blue-500 rounded-lg font-bold hover:bg-blue-100 transition duration-300"
                                onClick={handleAboutClick}
                            >
                                À propos de moi
                            </button>
                        </div>
                    </div>
                </section>

                <div ref={contentRef}>
                    {/* Animated Background */}

                    {/* Contact Information */}
                    <section className="py-4 sm:py-8 px-2 sm:px-4">
                        <div className="max-w-4xl mx-auto">
                            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 text-center text-white">Informations
                                de contact</h2>
                            <div className="flex flex-nowrap justify-between gap-2 sm:gap-4">
                                {Object.entries(cvData.contact).map(([key, item]) => (
                                    <div
                                        key={key}
                                        className="p-2 sm:p-4 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center w-1/4 sm:w-auto"
                                    >
                                        {item.icon &&
                                            <item.icon className="text-lg sm:text-xl md:text-2xl mb-1 text-white"/>}
                                        {key === 'email' && (
                                            <a
                                                href={`mailto:${item.data}`}
                                                className="text-xs sm:text-sm md:text-base font-semibold text-center text-white hover:text-blue-300 transition-colors duration-300 truncate w-full"
                                                title={item.data}
                                            >
                                                <span className="block sm:hidden">{item.label}</span>
                                                <span className="hidden sm:block">{item.data}</span>
                                            </a>
                                        )}
                                        {key === 'phone' && (
                                            <a
                                                href={`tel:${item.data.replace(/\s/g, '')}`}
                                                className="text-xs sm:text-sm md:text-base font-semibold text-center text-white hover:text-blue-300 transition-colors duration-300 truncate w-full"
                                                title={item.data}
                                            >
                                                <span className="block sm:hidden">{item.label}</span>
                                                <span className="hidden sm:block">{item.data}</span>
                                            </a>
                                        )}
                                        {key === 'location' && (
                                            <a
                                                href="https://maps.app.goo.gl/vd9asoo4pHhCwvKB7"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-xs sm:text-sm md:text-base font-semibold text-center text-white hover:text-blue-300 transition-colors duration-300 truncate w-full"
                                                title={item.data}
                                            >
                                                <span className="block sm:hidden">{item.label}</span>
                                                <span className="hidden sm:block">{item.data}</span>
                                            </a>
                                        )}
                                        {key === 'linkedin' && (
                                            <a
                                                href={item.href}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-xs sm:text-sm md:text-base font-semibold text-center text-white hover:text-blue-300 transition-colors duration-300 truncate w-full"
                                                title={item.data}
                                            >
                                                <span className="block sm:hidden">{item.label}</span>
                                                <span className="hidden sm:block">{item.data}</span>
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    {/* Summary Section */}
                    <section className="py-8 sm:py-16 px-4 bg-gradient-to-br from-gray-100 to-gray-200">
                        <div className="max-w-6xl mx-auto">
                            <div className="flex items-stretch">
                                {/* Titre */}
                                <div
                                    className="w-[calc(50%-2px)] flex flex-col justify-center items-center sm:items-end pr-2">
                                    <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 text-center sm:text-right text-gray-800">
                                        Profil professionnel
                                    </h2>
                                </div>

                                {/* Séparateur */}
                                <div className="w-px self-stretch bg-gray-300 m-2">
                                    <DynamicSeparator/>
                                </div>

                                {/* Résumé */}
                                <div className="w-[calc(50%-2px)] flex items-center pl-2">
                                    <p className="text-sm sm:text-base md:text-lg text-left text-gray-700 leading-tight sm:leading-relaxed">
                                        {cvData.summary}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Experience Section */}
                    <section className="py-8 sm:py-16 px-4">
                        <div className="max-w-6xl mx-auto">
                            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-8 text-center text-white">
                                Expérience professionnelle
                            </h2>
                            <div className="space-y-8">
                                {cvData.experience.map((exp, index) => (
                                    <div key={index}
                                         className={`flex items-stretch ${index % 2 === 0 ? 'flex-row-reverse' : 'flex-row'}`}>
                                        {/* Poste / Titre */}
                                        <div
                                            className={`w-[calc(50%-2px)] ${index % 2 === 0 ? 'pl-2' : 'pr-2'} flex flex-col justify-center`}>
                                            <div
                                                className={`p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ${index % 2 === 0 ? 'text-left' : 'text-right'}`}>
                                                <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-white">
                                                    {exp.title}
                                                </h3>
                                                <p className="text-sm sm:text-base text-white mt-2">
                                                    chez {exp.company} ({exp.period})
                                                </p>
                                            </div>
                                        </div>

                                        {/* Trait séparateur */}
                                        <div className="w-px self-stretch bg-white m-2">
                                            <DynamicSeparator/>
                                        </div>

                                        {/* Responsabilités */}
                                        <div
                                            className={`w-[calc(50%-2px)] ${index % 2 === 0 ? 'pr-2' : 'pl-2'} flex items-center`}>
                                            <div
                                                className={`p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ${index % 2 === 0 ? 'text-right' : 'text-left'}`}>
                                                <ul className="list-disc list-inside text-sm sm:text-base text-white leading-relaxed">
                                                    {exp.responsibilities.map((resp, idx) => (
                                                        <li key={idx} className="mb-2">{resp}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    {/* Skills Section */}
                    {/* Expertises techniques Section */}
                    <section className="py-8 sm:py-16 px-4 bg-gray-100">
                        <div className="max-w-4xl mx-auto">
                            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-8 text-center text-gray-800">Expertises
                                techniques</h2>

                            {/* Compétences back-end */}
                            <div className="mb-8 flex items-stretch">
                                <div className="w-[calc(50%-2px)] pr-2">
                                    <div
                                        className="text-sm sm:text-base md:text-lg text-gray-700 leading-tight sm:leading-relaxed">
                                        {cvData.hardSkills.backEnd.map((skill, index) => (
                                            <div key={index} className="mb-4 text-right">
                                                <h3 className="text-lg sm:text-xl font-semibold">{skill.name}</h3>
                                                <p>{skill.description}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="w-px self-stretch bg-gray-300 m-2">
                                    <DynamicSeparator/>
                                </div>
                                <div className="w-[calc(50%-2px)] pl-2">
                                    <div className="relative shadow-lg rounded-lg p-4 sm:p-6">
                                        <DynamicBackground speed={0.0001}/>
                                        <Skills3D skills={cvData.hardSkills.backEnd} isClockwise={true}/>
                                    </div>
                                </div>
                            </div>

                            {/* Compétences front-end */}
                            <div className="mb-8 flex items-stretch">
                                <div className="w-[calc(50%-2px)] pr-2">
                                    <div className="relative shadow-lg rounded-lg p-4 sm:p-6">
                                        <DynamicBackground speed={0.0001}/>
                                        <Skills3D skills={cvData.hardSkills.frontEnd} isClockwise={false}/>
                                    </div>
                                </div>
                                <div className="w-px self-stretch bg-gray-300 m-2">
                                    <DynamicSeparator/>
                                </div>
                                <div className="w-[calc(50%-2px)] pl-2">
                                    <div
                                        className="text-sm sm:text-base md:text-lg text-gray-700 leading-tight sm:leading-relaxed">
                                        {cvData.hardSkills.frontEnd.map((skill, index) => (
                                            <div key={index} className="mb-4 text-left">
                                                <h3 className="text-lg sm:text-xl font-semibold">{skill.name}</h3>
                                                <p>{skill.description}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Autres compétences */}
                            <div className="flex items-stretch">
                                <div className="w-[calc(50%-2px)] pr-2 flex items-center justify-end">
                                    <ul className="list-none text-sm sm:text-base md:text-lg font-normal leading-tight sm:leading-relaxed text-gray-600 text-right">
                                        {cvData.hardSkills.other.map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="w-px self-stretch bg-gray-300 m-2">
                                    <DynamicSeparator/>
                                </div>
                                <div className="w-[calc(50%-2px)] pl-2 flex flex-col justify-center">
                                    <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 mb-4 text-left">
                                        Autres expertises techniques
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Section Compétences */}
                    <section className="py-8 sm:py-12 px-2 sm:px-4">
                        <div className="max-w-4xl mx-auto">
                            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6 text-center text-white">Compétences</h2>
                            <div className="grid grid-cols-2 gap-2 sm:gap-4">
                                {cvData.softSkills.map((skill, index) => (
                                    <div key={index}
                                         className="p-2 sm:p-4 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300">
                                        <div className="flex items-start space-x-2 sm:space-x-3">
                                            <div
                                                className="flex-shrink-0 w-6 h-6 sm:w-8 sm:h-8 bg-gray-200 rounded-full flex items-center justify-center">
                                                <span
                                                    className="text-xs sm:text-sm font-bold text-gray-500">{index + 1}</span>
                                            </div>
                                            <div className="flex-grow text-left">
                                                <span className="text-xs sm:text-sm md:text-base font-semibold text-white">
                                                    {skill}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    {/* Section Formation */}
                    <section className="py-8 sm:py-12 px-2 sm:px-4">
                        <div className="max-w-4xl mx-auto">
                            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6 text-center text-white">Formation</h2>
                            <div className="grid grid-cols-2 gap-2 sm:gap-4">
                                {cvData.education.map((edu, index) => (
                                    <div key={index} className="p-2 sm:p-4 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300">
                                        <h3 className="text-sm sm:text-base md:text-lg font-semibold text-white mb-1 sm:mb-2">
                                            {edu.degree} ({edu.year})
                                        </h3>
                                        <p className="text-xs sm:text-sm text-white mb-2 sm:mb-3">{edu.institution}</p>
                                        {edu.certificateLink && (
                                            <a
                                                href={edu.certificateLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-xs sm:text-sm text-blue-400 hover:underline"
                                            >
                                                Voir le certificat
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    {/* Languages Section */}
                    <section className="py-8 sm:py-16 px-4 bg-white">
                        <div className="max-w-4xl mx-auto">
                            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-8 text-center text-gray-800">Langues</h2>

                            <div className="flex items-center justify-between">
                                {/* Français */}
                                <div className="flex flex-col items-center w-[calc(50%-2px)]">
                                    <span
                                        className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2 sm:mb-4">Français</span>
                                    <div className="flex">
                                        {Array.from({length: 5}).map((_, i) => (
                                            <svg
                                                key={i}
                                                className={`w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 ${
                                                    i + 1 <= 4.5 ? 'fill-current text-yellow-400' : (i + 0.5 === 4.5 ? 'fill-current text-yellow-400' : 'fill-current text-gray-300')
                                                }`}
                                                fill="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M12 2.25l3.16 6.42L22 9.24l-4.91 4.79 1.16 6.77L12 17.7l-6.25 3.1 1.16-6.77L2 9.24l6.84-1.07L12 2.25z"/>
                                            </svg>
                                        ))}
                                    </div>
                                </div>

                                {/* Trait séparateur */}
                                <div className="h-16 sm:h-20 md:h-24 w-px bg-gray-300">
                                    <DynamicSeparator/>
                                </div>

                                {/* Anglais */}
                                <div className="flex flex-col items-center w-[calc(50%-2px)]">
                                    <span
                                        className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2 sm:mb-4">Anglais</span>
                                    <div className="flex">
                                        {Array.from({length: 5}).map((_, i) => (
                                            <svg
                                                key={i}
                                                className={`w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 ${
                                                    i + 1 <= 2.5 ? 'fill-current text-yellow-400' : (i + 0.5 === 2.5 ? 'fill-current text-yellow-400' : 'fill-current text-gray-300')
                                                }`}
                                                fill="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M12 2.25l3.16 6.42L22 9.24l-4.91 4.79 1.16 6.77L12 17.7l-6.25 3.1 1.16-6.77L2 9.24l6.84-1.07L12 2.25z"/>
                                            </svg>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>

        </div>
    );
};

export default CVPage;