import React, { useEffect, useRef, useState } from 'react';

const DynamicSeparator = ({ isHorizontal = false }) => {
    const canvasRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(window.innerWidth < 768);
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        const resizeCanvas = () => {
            canvas.width = canvas.clientWidth;
            canvas.height = canvas.clientHeight;
        };

        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        const colors = ['#34A853', '#EA4335', '#4285F4', '#FBBC05'];
        let colorIndex = 0;

        let lastUpdateTime = 0;
        const updateInterval = isMobile ? 100 : 50; // Moins de mises à jour sur mobile

        const draw = (currentTime) => {
            if (currentTime - lastUpdateTime > updateInterval) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                const gradient = ctx.createLinearGradient(
                    0, 0,
                    isHorizontal ? canvas.width : 0,
                    isHorizontal ? 0 : canvas.height
                );
                const startColorIndex = Math.floor(colorIndex) % colors.length;
                const endColorIndex = (startColorIndex + 1) % colors.length;

                gradient.addColorStop(0, colors[startColorIndex]);
                gradient.addColorStop(1, colors[endColorIndex]);

                ctx.fillStyle = gradient;
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                colorIndex = (colorIndex + 0.02) % colors.length;
                lastUpdateTime = currentTime;
            }

            animationFrameId = requestAnimationFrame(draw);
        };

        draw();

        return () => {
            window.removeEventListener('resize', resizeCanvas);
            cancelAnimationFrame(animationFrameId);
        };
    }, [isHorizontal, isMobile]);

    return (
        <canvas
            ref={canvasRef}
            className={isHorizontal ? "w-full h-1" : "w-1 h-full"}
        />
    );
};

export default DynamicSeparator;