import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { register } from './serviceWorkerRegistration';

// Création de la racine React
const root = ReactDOM.createRoot(document.getElementById('root'));

// Rendu de l'application
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// Enregistrement du Service Worker
console.log('[Index] Tentative d_enregistrement du Service Worker...');
register();

// Note: La fonction register gère maintenant automatiquement les mises à jour,
// sans nécessiter d'interaction utilisateur via un modal.