import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import heroImage from '../PWAPage/13.png'
import logoImage from '../PWAPage/192x192.png';
import OptimizedImage from "../../components/OptimizedImage";
import NavigationBar from "../../components/NavigationBar";
import { Wifi, Package, Wrench, Satellite, Zap } from 'lucide-react';
import { Card, CardContent } from '../../components/ui/card';
import {blogs} from "../BlogData/BlogData";
import BlogReelCarousel from "../BlogReelCarousel";

const starlingFeatures = [
    {
        name: "Internet haut débit partout",
        icon: Wifi,
        color: "text-blue-500",
        description: "Profitez d'une connexion Internet rapide et fiable, même dans les zones rurales ou reculées. Starlink offre des vitesses pouvant atteindre jusqu'à 150 Mbps, permettant le streaming, les jeux en ligne et le travail à distance sans interruption.",
        seoKeywords: "connexion satellite Starlink, Internet haut débit satellite"
    },
    {
        name: "Faible latence",
        icon: Zap,
        color: "text-yellow-500",
        description: "Expérimentez une latence exceptionnellement basse, idéale pour le streaming en haute définition, les jeux en ligne réactifs et les appels vidéo fluides. La technologie de pointe de Starlink garantit une expérience en ligne comparable à celle des connexions terrestres.",
        seoKeywords: "latence faible Starlink, Internet satellite rapide"
    },
    {
        name: "Installation simple",
        icon: Package,
        color: "text-green-500",
        description: "Bénéficiez d'une installation rapide et facile grâce aux kits Starlink complets. Tout le nécessaire est fourni, y compris l'antenne satellite, le routeur et les câbles. Des accessoires supplémentaires sont disponibles pour optimiser votre installation selon vos besoins spécifiques.",
        seoKeywords: "installation Starlink, kit Starlink"
    },
    {
        name: "Accessoires optimisés",
        icon: Wrench,
        color: "text-purple-500",
        description: "Améliorez votre expérience Starlink avec notre gamme complète d'accessoires. Des montures pour différentes surfaces aux câbles supplémentaires, en passant par les routeurs améliorés et les boîtiers de protection, personnalisez votre installation pour des performances optimales.",
        seoKeywords: "accessoires Starlink, optimisation Starlink"
    }
];

const installationSteps = [
    {
        icon: Package,
        color: "text-blue-500",
        title: "1. Commande du kit",
        description: "Commandez votre kit Starlink complet, qui inclut l'antenne satellite, le routeur Wi-Fi, les câbles et les instructions d'installation détaillées. Choisissez les accessoires adaptés à votre situation."
    },
    {
        icon: Wrench,
        color: "text-green-500",
        title: "2. Préparation et installation",
        description: "Choisissez un emplacement dégagé pour votre antenne. Assemblez la monture et fixez l'antenne selon les instructions. Connectez les câbles entre l'antenne et le routeur à l'intérieur de votre domicile."
    },
    {
        icon: Wifi,
        color: "text-yellow-500",
        title: "3. Configuration du réseau",
        description: "Branchez le routeur Starlink et suivez les instructions de l'application mobile pour configurer votre réseau. Personnalisez vos paramètres Wi-Fi et sécurisez votre connexion."
    },
    {
        icon: Satellite,
        color: "text-purple-500",
        title: "4. Connexion et optimisation",
        description: "Une fois installé, votre système Starlink se connectera automatiquement aux satellites. Effectuez des tests de vitesse et ajustez si nécessaire la position de l'antenne pour optimiser votre connexion."
    }
];

const NetworkPage = () => {
    const NETWORK = blogs.filter(blog => blog.service === "NETWORK");
    const navigate = useNavigate();
    const featuresRef = useRef(null);

    const handleStartClick = () => {
        featuresRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const handleContactClick = () => {
        navigate('/contact'); // Assurez-vous que cette route existe dans votre configuration
    };

    return (
        <div className="bg-white text-gray-800 min-h-screen ">
            <Helmet>
                <title>Connexion Internet Starlink | Haute Vitesse Partout</title>
                <meta name="description"
                      content="Découvrez la connexion Internet par satellite Starlink. Haut débit, faible latence, installation simple. Idéal pour les zones rurales et reculées."/>
                <meta name="keywords"
                      content="Starlink, Internet par satellite, haut débit, faible latence, zones rurales, installation simple"/>
                <meta property="og:title" content="Connexion Internet Starlink | Haute Vitesse Partout"/>
                <meta property="og:description"
                      content="Internet haut débit par satellite Starlink. Connexion rapide et fiable, même dans les zones reculées. Installation facile et accessoires optimisés."/>
                <meta property="og:image" content={heroImage}/>
                <meta property="og:url" content={window.location.href}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <NavigationBar/>

            {/* COVER Section */}
            <section className="relative h-screen flex flex-col items-center justify-center overflow-hidden ">
                {/* Image de fond */}
                <div className="absolute inset-0 z-0">
                    <OptimizedImage
                        src={heroImage}
                        alt="Background"
                        className="absolute inset-0 w-full h-full object-cover"
                        sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 100vw"
                        style={{display: 'block', width: '100%', height: '100%'}}
                    />
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                {/* Logo */}
                <motion.img
                    src={logoImage}
                    alt="Logo"
                    className="w-32 h-32 mb-8 relative z-10"
                    initial={{opacity: 0, y: -50}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                />

                {/* Contenu */}
                <div className="relative z-10 text-center px-4">
                    <motion.h1
                        className="text-5xl md:text-7xl font-bold text-white mb-4"
                        initial={{opacity: 0, y: -50}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5, delay: 0.2}}
                    >
                        Connexion Internet Starlink
                    </motion.h1>

                    <motion.button
                        className="mt-8 px-8 py-3 bg-white text-blue-500 rounded-lg font-bold hover:bg-blue-100 transition duration-300"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay: 0.4, duration: 0.5}}
                        onClick={handleStartClick}
                    >
                        Découvrir
                    </motion.button>
                </div>
            </section>

            {/* FEATURES sections */}
            <section ref={featuresRef} className="py-20 px-4">
                <h2 className="text-4xl font-bold text-center mb-12">Caractéristiques principales</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
                    {starlingFeatures.map((feature, index) => (
                        <motion.div
                            key={index}
                            initial={{opacity: 0, y: 50}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: index * 0.1}}
                            className="h-full"
                        >
                            <Card className="hover:shadow-xl transition duration-300 h-full flex flex-col">
                                <CardContent className="p-6 flex flex-col flex-grow">
                                    <div className="flex items-center mb-4">
                                        <feature.icon className={`w-8 h-8 ${feature.color}`}/>
                                        <h3 className="text-xl font-semibold ml-4">{feature.name}</h3>
                                    </div>
                                    <p className="text-gray-600 flex-grow text-left leading-loose ">{feature.description}</p>

                                </CardContent>
                            </Card>
                        </motion.div>
                    ))}
                </div>
            </section>

            {/* INSTALLATION Section */}
            <section className="py-16 px-4">
                <div className="container mx-auto">
                    <h2 className="text-3xl font-bold text-center mb-12">Processus d'installation</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                        {installationSteps.map((step, index) => (
                            <div key={index} className="flex flex-col items-center text-center">
                                <step.icon className={`w-16 h-16 ${step.color} mb-4`}/>
                                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                                <p className="text-gray-600 leading-loose">{step.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Blog Section */}

            <BlogReelCarousel blogs={NETWORK} spacing={7}/>

            {/* FOOTER Section */}
            <section className="relative h-[330px] px-4 text-white overflow-hidden">
                {/* Image de fond coupée */}
                <div className="absolute inset-0 z-0">
                    <OptimizedImage
                        src={heroImage}  // Utilise l'image importée
                        alt="Background"
                        className="absolute inset-0 w-full h-full object-cover object-center"
                        sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 100vw"
                        style={{
                            transform: 'scale(1.5)',
                            transformOrigin: 'center bottom',
                            display: 'block',
                            width: '100%',
                            height: '100%'
                        }}
                    />
                    {/* Ajout de l'effet sombre */}
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <div className="relative z-10 max-w-4xl mx-auto text-center pt-10">
                    <h2 className="text-4xl font-bold mb-6">Prêt à commencer ?</h2>
                    <p className="text-xl mb-8">
                        Contactez-nous dès aujourd'hui pour discuter de votre prochain projet.
                    </p>
                    <button
                        onClick={handleContactClick}
                        className="px-8 py-3 rounded-lg font-bold text-white bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg hover:bg-opacity-30 transition duration-300"
                    >
                        Commencez votre projet
                    </button>
                </div>
            </section>

        </div>
    );
};

export default NetworkPage;