import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const Skills3D = ({ skills, isClockwise = true }) => {
    const mountRef = useRef(null);

    // Paramètres ajustables
    const iconSize = 90;
    const baseRadius = 200;
    const maxRadius = 250;
    const rotationSpeed = 0.005;

    useEffect(() => {
        if (!mountRef.current || !skills || skills.length === 0) return;

        const width = mountRef.current.clientWidth;
        const height = mountRef.current.clientHeight;

        // Créer le renderer avec fond transparent
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(width, height);
        mountRef.current.appendChild(renderer.domElement);

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        camera.position.z = 400;

        const colors = [
            new THREE.Color(0xff0000), new THREE.Color(0x00ff00),
            new THREE.Color(0x0000ff), new THREE.Color(0xffff00)
        ];

        const adjustedSkills = skills.length < 4 ? [...skills, { icon: null, name: 'Extra Point' }] : skills;

        const group = new THREE.Group();
        scene.add(group);

        const iconObjects = adjustedSkills.map((skill, index) => {
            const phi = Math.acos(-1 + (2 * index) / adjustedSkills.length);
            const theta = Math.sqrt(adjustedSkills.length * Math.PI) * phi;

            const x = baseRadius * Math.cos(theta) * Math.sin(phi);
            const y = baseRadius * Math.sin(theta) * Math.sin(phi);
            const z = baseRadius * Math.cos(phi);

            let sprite;
            if (skill.icon) {
                const texture = new THREE.TextureLoader().load(skill.icon);
                const material = new THREE.SpriteMaterial({ map: texture });
                sprite = new THREE.Sprite(material);
            } else {
                sprite = new THREE.Sprite(new THREE.SpriteMaterial({ color: 0xffffff }));
            }
            sprite.scale.set(iconSize, iconSize, 1);
            sprite.position.set(x, y, z);
            group.add(sprite);

            return {
                sprite,
                color: colors[index % colors.length],
                basePosition: new THREE.Vector3(x, y, z)
            };
        });

        const lineGeometry = new THREE.BufferGeometry();
        const lineMaterial = new THREE.LineBasicMaterial({ vertexColors: true, linewidth: 2 });
        const linePositions = new Float32Array(adjustedSkills.length * (adjustedSkills.length - 1) * 3);
        const lineColors = new Float32Array(adjustedSkills.length * (adjustedSkills.length - 1) * 3);
        lineGeometry.setAttribute('position', new THREE.BufferAttribute(linePositions, 3));
        lineGeometry.setAttribute('color', new THREE.BufferAttribute(lineColors, 3));

        const lines = new THREE.LineSegments(lineGeometry, lineMaterial);
        group.add(lines);

        const updateLines = () => {
            let index = 0;
            for (let i = 0; i < iconObjects.length; i++) {
                for (let j = i + 1; j < iconObjects.length; j++) {
                    const start = iconObjects[i].sprite.position;
                    const end = iconObjects[j].sprite.position;
                    linePositions.set([start.x, start.y, start.z, end.x, end.y, end.z], index * 6);
                    lineColors.set([
                        ...iconObjects[i].color.toArray(),
                        ...iconObjects[j].color.toArray()
                    ], index * 6);
                    index++;
                }
            }
            lineGeometry.attributes.position.needsUpdate = true;
            lineGeometry.attributes.color.needsUpdate = true;
        };

        let time = 0;
        const animate = () => {
            time += rotationSpeed;

            iconObjects.forEach((obj, index) => {
                const noise = Math.sin(time + index) * (maxRadius - baseRadius);
                const newPos = obj.basePosition.clone().multiplyScalar(1 + noise / baseRadius);
                obj.sprite.position.copy(newPos);
            });

            updateLines();

            // Ajuster le sens de rotation en fonction de isClockwise
            const rotationDirection = isClockwise ? 1 : -1;
            group.rotation.x += rotationSpeed * rotationDirection;
            group.rotation.y += rotationSpeed * 1.5 * rotationDirection;

            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };

        animate();

        const handleResize = () => {
            if (!mountRef.current) return;
            const newWidth = mountRef.current.clientWidth;
            const newHeight = mountRef.current.clientHeight;
            renderer.setSize(newWidth, newHeight);
            camera.aspect = newWidth / newHeight;
            camera.updateProjectionMatrix();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            scene.remove(group);
            renderer.dispose();
            if (mountRef.current) {
                mountRef.current.removeChild(renderer.domElement);
            }
        };
    }, [skills, isClockwise]);

    return <div ref={mountRef} style={{ width: '100%', height: '300px', position: 'relative' }} />;
};

export default Skills3D;
