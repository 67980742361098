import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import heroImage from '../PWAPage/11.jpg';
import logoImage from '../PWAPage/192x192.png';
import OptimizedImage from "../../components/OptimizedImage";
//import BlogReelHorizontalGrid from "../BlogReelHorizontalGrid";
import BlogReelCarousel from "../BlogReelCarousel";
import {blogs} from "../BlogData/BlogData";
import NavigationBar from "../../components/NavigationBar";
import heroImage4 from "../PWAPage/optimization.jpg";

const AlibabaPage = () => {

    /*const blogs = [
        {
            id: 1,
            title: "Comment améliorer votre productivité",
            coverImage: heroImage4,
            contenu: "Contenu détaillé sur l'amélioration de la productivité...",
            service: "Conseils professionnels",
        },
        {
            id: 2,
            title: "Les tendances technologiques de 2024",
            coverImage: heroImage,
            contenu: "Analyse des technologies émergentes...",
            service: "Technologie",
        },
        {
            id: 3,
            title: "Les tendances technologiques de 2024",
            coverImage: heroImage,
            contenu: "Analyse des technologies émergentes...",
            service: "Technologie",
        },
        {
            id: 3,
            title: "Les tendances technologiques de 2024",
            coverImage: heroImage,
            contenu: "Analyse des technologies émergentes...",
            service: "Technologie",
        },
        {
            id: 4,
            title: "Les tendances technologiques de 2024",
            coverImage: heroImage,
            contenu: "Analyse des technologies émergentes...",
            service: "Technologie",
        },
    ]*/
    const navigate = useNavigate();
    const TechnologieBlogs = blogs.filter(blog => blog.service === "PWA");  // Filtrer par service
    const handleContactClick = () => {
        navigate('/contact');
    };

    return (
        <div className="bg-white text-gray-800 min-h-screen pb-32">
            <NavigationBar />
            <section className="relative h-screen flex flex-col items-center justify-center overflow-hidden">
                {/* Image de fond */}
                <div className="absolute inset-0 z-0">
                    <OptimizedImage
                        src={heroImage}  // Utilise l'image importée
                        alt="Background"
                        className="absolute inset-0 w-full h-full object-cover"
                        sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 100vw"
                        style={{display: 'block', width: '100%', height: '100%'}}
                    />
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                {/* Logo */}
                <motion.img
                    src={logoImage}
                    alt="Logo"
                    className="w-32 h-32 mb-8 relative z-10"
                    initial={{opacity: 0, y: -50}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                />

                {/* Contenu */}
                <div className="relative z-10 text-center px-4">
                    <motion.h1
                        className="text-5xl md:text-7xl font-bold text-white mb-4"
                        initial={{opacity: 0, y: -50}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5, delay: 0.2}}
                    >
                        En cours de production
                    </motion.h1>

                    <motion.button
                        className="mt-8 px-8 py-3 bg-white text-blue-500 rounded-lg font-bold hover:bg-blue-100 transition duration-300"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay: 0.4, duration: 0.5}}
                        onClick={handleContactClick}
                    >
                        Contact
                    </motion.button>
                </div>
            </section>

                <BlogReelCarousel blogs={TechnologieBlogs} spacing={7}/>

        </div>
    );
};

export default AlibabaPage;