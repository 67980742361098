import React, { useState, useEffect, useRef }  from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import OptimizedImage from "../../components/OptimizedImage";
import heroImage from '../PWAPage/7.jpg'
import logoImage from '../PWAPage/192x192.png';
import NavigationBar from "../../components/NavigationBar";
import { BarChart2, Eye, TrendingUp, FileText, HelpCircle ,Award } from 'lucide-react';
import { Card, CardContent } from '../../components/ui/card';
import { LineChart, Line, AreaChart, Area, BarChart, Bar, PieChart ,Legend, Pie, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid, Cell } from 'recharts';

const generateRandomData = (length, max) => {
    return Array.from({ length }, () => Math.floor(Math.random() * max));
};


const faqs = [
    { question: "Quelles données pouvez-vous analyser ?", answer: "Nous pouvons analyser toutes sortes de données, des ventes aux opérations en passant par les comportements clients." },
    { question: "Comment garantissez-vous la sécurité des données ?", answer: "Nous utilisons des technologies de cryptage avancées pour assurer la confidentialité et la sécurité de vos données." },
    { question: "Combien de temps dure un projet d'analyse de données ?", answer: "Cela dépend de la complexité des données, mais la plupart des projets durent entre 2 et 6 semaines." }
];

const AnalyticsPage = () => {

    const [predictiveData, setPredictiveData] = useState(generateRandomData(6, 1000));
    const [visualizationData, setVisualizationData] = useState(generateRandomData(5, 1000));
    const [performanceData, setPerformanceData] = useState(generateRandomData(4, 1000));
    const [bigDataData, setBigDataData] = useState(generateBigDataData());

    function generateBigDataData() {
        const rawData = Array.from({ length: 4 }, () => Math.floor(Math.random() * 100));
        const total = rawData.reduce((sum, value) => sum + value, 0);
        return rawData.map(value => Math.round((value / total) * 100));
    }


    useEffect(() => {
        const interval = setInterval(() => {
            setPredictiveData(generateRandomData(6, 1000));
            setVisualizationData(generateRandomData(5, 1000));
            setPerformanceData(generateRandomData(4, 1000));
            setBigDataData(generateBigDataData());
        }, 3000); // Update every 5 seconds

        return () => clearInterval(interval);
    }, []);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const analyticsFeatures = [
        {
            name: "Analyse prédictive",
            icon: BarChart2,
            color: "text-blue-500",
            description: "Utilisation de bibliothèques Python avancées (Pandas, NumPy, etc.) pour des prévisions précises et une analyse approfondie des tendances.",
            chart: (
                <ResponsiveContainer width="100%" height={200}>
                    <LineChart data={predictiveData.map((value, index) => ({ name: `Day ${index + 1}`, value }))}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} dot={false} animationDuration={300} />
                    </LineChart>
                </ResponsiveContainer>
            )
        },
        {
            name: "Visualisation des données",
            icon: Eye,
            color: "text-green-500",
            description: "Création de dashboards interactifs et de visualisations percutantes pour une compréhension claire et rapide de vos données complexes.",
            chart: (
                <ResponsiveContainer width="100%" height={200}>
                    <AreaChart data={visualizationData.map((value, index) => ({ name: `Category ${index + 1}`, value, secondValue: value * 0.6 }))}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Area type="monotone" dataKey="value" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.3} animationDuration={300} />
                        <Area type="monotone" dataKey="secondValue" stroke="#8884d8" fill="#8884d8" fillOpacity={0.3} animationDuration={300} />
                    </AreaChart>
                </ResponsiveContainer>
            )
        },
        {
            name: "Amélioration des performances",
            icon: TrendingUp,
            color: "text-red-500",
            description: "Extraction et analyse de données pertinentes pour optimiser les processus de l'entreprise et augmenter l'efficacité opérationnelle.",
            chart: (
                <ResponsiveContainer width="100%" height={200}>
                    <BarChart data={performanceData.map((value, index) => ({ name: `Q${index + 1}`, value }))}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="value" fill="#8884d8" animationDuration={300} />
                    </BarChart>
                </ResponsiveContainer>
            )
        },
        {
            name: "Traitement Big Data",
            icon: FileText,
            color: "text-yellow-500",
            description: "Solutions avancées pour gérer, traiter et analyser de grands volumes de données en temps réel, permettant des insights rapides et précis.",
            chart: (
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Pie
                            data={bigDataData.map((value, index) => ({ name: `Group ${index + 1}`, value }))}
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            animationDuration={300}
                            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                        >
                            {bigDataData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                            formatter={(value, entry, index) => (
                                <span style={{ color: COLORS[index % COLORS.length] }}>
                                    {`Group ${index + 1}: ${bigDataData[index]}%`}
                                </span>
                            )}
                        />
                    </PieChart>
                </ResponsiveContainer>
            )
        }
    ];




    const navigate = useNavigate();
    const featuresRef = useRef(null);

    const handleContactClick = () => {
        navigate('/contact');
    };

    const handleStartClick = () => {
        featuresRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="bg-white text-gray-800 min-h-screen ">
            <Helmet>
                <title>Analyse de Données Avancée | Décisions Stratégiques</title>
                <meta name="description"
                      content="Transformez vos données en insights exploitables. Analyse prédictive, visualisation et solutions Big Data pour des décisions stratégiques éclairées."/>
                <meta name="keywords"
                      content="analyse de données, décisions stratégiques, données exploitables, analyse prédictive, visualisation de données, Python, Big Data"/>
            </Helmet>
            <NavigationBar/>
            {/* Section Hero (inchangée) */}

            {/* ... (le contenu de la section hero reste inchangé) */}
            <section className="relative h-screen flex flex-col items-center justify-center overflow-hidden">
                <div className="absolute inset-0 z-0">
                    <OptimizedImage
                        src={heroImage}
                        alt="Background"
                        className="absolute inset-0 w-full h-full object-cover"
                        sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 100vw"
                        style={{display: 'block', width: '100%', height: '100%'}}
                    />
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <motion.img
                    src={logoImage}
                    alt="Logo"
                    className="w-32 h-32 mb-8 relative z-10"
                    initial={{opacity: 0, y: -50}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                />

                <div className="relative z-10 text-center px-4">
                    <motion.h1
                        className="text-5xl md:text-7xl font-bold text-white mb-4"
                        initial={{opacity: 0, y: -50}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5, delay: 0.2}}
                    >
                        Analyse de Données Avancée
                    </motion.h1>



                    <motion.button
                        className="mt-8 px-8 py-3 bg-white text-blue-500 rounded-lg font-bold hover:bg-blue-100 transition duration-300"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay: 0.4, duration: 0.5}}
                        onClick={handleStartClick}
                    >
                        Découvrir nos services
                    </motion.button>
                </div>
            </section>


            {/* Section Nos Services d'Analyse */}
            <section ref={featuresRef} className="py-20 px-4">
                <div className="container mx-auto">
                    <h2 className="text-4xl font-bold text-center mb-12">Nos Services d'Analyse</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {analyticsFeatures.map((feature, index) => (
                            <Card key={index} className="hover:shadow-xl transition duration-300 h-full flex flex-col">
                                <CardContent className="p-6 flex flex-col flex-grow">
                                    <div className="flex items-center mb-4">
                                        <feature.icon className={`w-8 h-8 ${feature.color}`}/>
                                        <h3 className="text-xl font-semibold ml-4">{feature.name}</h3>
                                    </div>
                                    <p className="text-gray-600 mb-4 text-left leading-loose">{feature.description}</p>
                                    <div className="mt-auto">
                                        {feature.chart}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>

            {/* Section Bénéfices */}
            <section className="py-16 bg-gray-100">
                <div className="container mx-auto px-4">
                    <div className="flex items-center justify-center mb-12">
                        <Award className="w-10 h-10 text-blue-500 mr-4"/>
                        <h2 className="text-3xl font-bold">Bénéfices pour votre Entreprise</h2>
                    </div>
                    <div className="max-w-3xl mx-auto">
                        <p className="text-lg text-gray-700 mb-8 text-left leading-loose">
                            En choisissant notre service d'analyse de données, vous bénéficiez d'une compréhension
                            approfondie de vos opérations. Nos solutions sur mesure vous permettent de prendre des
                            décisions basées sur des données concrètes, augmentant ainsi vos profits et optimisant vos
                            processus internes.
                        </p>
                        <p className="text-lg text-gray-700 text-left leading-loose">
                            Grâce à Python, un langage de programmation puissant et flexible, nous sommes capables de
                            manipuler des ensembles de données volumineux et complexes rapidement et efficacement, tout
                            en garantissant des résultats précis et exploitables.
                        </p>
                    </div>
                </div>
            </section>

            {/* Section CTA */}
            <section className="relative h-[330px] px-4 text-white overflow-hidden">
                {/* Image de fond coupée */}
                <div className="absolute inset-0 z-0">
                    <OptimizedImage
                        src={heroImage}  // Utilise l'image importée
                        alt="Background"
                        className="absolute inset-0 w-full h-full object-cover object-center"
                        sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 100vw"
                        style={{
                            transform: 'scale(1.5)',
                            transformOrigin: 'center bottom',
                            display: 'block',
                            width: '100%',
                            height: '100%'
                        }}
                    />
                    {/* Ajout de l'effet sombre */}
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <div className="relative z-10 max-w-4xl mx-auto text-center pt-10">
                    <h2 className="text-4xl font-bold mb-6">Prêt à commencer ?</h2>
                    <p className="text-xl mb-8">
                        Contactez-nous dès aujourd'hui pour discuter de votre prochain projet.
                    </p>
                    <button
                        onClick={handleContactClick}
                        className="px-8 py-3 rounded-lg font-bold text-white bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg hover:bg-opacity-30 transition duration-300"
                    >
                        Commencez votre projet
                    </button>
                </div>
            </section>

        </div>
    );
};

export default AnalyticsPage;