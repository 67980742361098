import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Share2 } from 'lucide-react';
import OptimizedImage from "../components/OptimizedImage";

const BlogReel = ({
                      title,
                      coverImage, // dynamic image
                      readMoreLink,
                      onShare,
                      service,
                      readMoreText = "En savoir plus",
                  }) => {
    // Function to handle sharing
    const handleShare = async () => {
        if (navigator.share) {
            try {
                const response = await fetch(coverImage);
                const blob = await response.blob();
                const file = new File([blob], 'image.jpg', { type: blob.type });

                const shareUrl = `${window.location.origin}${readMoreLink}?title=${encodeURIComponent(title)}`;

                await navigator.share({
                    title: title,
                    text: `Découvrez cet article: ${title}`,
                    url: shareUrl,
                    files: [file]
                });
                console.log('Article partagé avec succès!');
            } catch (error) {
                console.log('Erreur lors du partage:', error);
                try {
                    const shareUrl = `${window.location.origin}${readMoreLink}?title=${encodeURIComponent(title)}`;
                    await navigator.share({
                        title: title,
                        text: `Découvrez cet article: ${title}`,
                        url: shareUrl
                    });
                } catch (fallbackError) {
                    console.log('Erreur lors du partage sans image:', fallbackError);
                    onShare(`${window.location.origin}${readMoreLink}?title=${encodeURIComponent(title)}`);
                }
            }
        } else {
            onShare(`${window.location.origin}${readMoreLink}?title=${encodeURIComponent(title)}`);
        }
    };

    return (
        <>
        <Helmet>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={`Découvrez notre article sur ${service}`} />
            <meta property="og:image" content={coverImage} />
            <meta property="og:url" content={window.location.origin + readMoreLink} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <div className="relative w-[200px] h-[300px] overflow-hidden rounded-lg shadow-lg">
            {coverImage && (
                <OptimizedImage
                    src={coverImage}  // Dynamically setting the optimized image source
                    alt={title}  // Using the title for alt text
                    className="absolute inset-0 w-full h-full object-cover"
                    sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 100vw"  // Responsive sizes
                    style={{ display: 'block', width: '100%', height: '100%' }}
                />
            )}

            <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent" />

            <div className="absolute inset-0 flex flex-col justify-end p-3 text-white">
                <span className="text-xs font-semibold mb-1 text-left">{service}</span>
                <h2 className="text-lg font-bold mb-2 line-clamp-2 text-left">{title}</h2>

                <div className="flex justify-between items-center">
                    <Link
                        to={readMoreLink}
                        className="bg-[#4285F4] text-white px-2 py-1 text-xs rounded-md font-medium hover:bg-opacity-80 transition-colors"
                    >
                        {readMoreText}
                    </Link>

                    <button
                        onClick={handleShare}
                        aria-label="Partager"
                        className="bg-white text-[#4285F4] p-1 rounded-full hover:bg-opacity-80 transition-colors"
                    >
                        <Share2 size={16} />
                    </button>
                </div>
            </div>
        </div>
        </>
    );
};

export default BlogReel;
