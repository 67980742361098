import { useEffect } from 'react';

const useGlobalImagePreload = () => {
    useEffect(() => {
        const openRequest = indexedDB.open('ImageCacheDB', 1);

        openRequest.onupgradeneeded = () => {
            const db = openRequest.result;
            if (!db.objectStoreNames.contains('images')) {
                db.createObjectStore('images', { keyPath: 'src' });
            }
        };

        openRequest.onsuccess = () => {
            const db = openRequest.result;
            const transaction = db.transaction('images', 'readwrite');
            const store = transaction.objectStore('images');

            // Vérifier le cache
            const now = Date.now();
            const getAllRequest = store.getAll();

            getAllRequest.onsuccess = () => {
                const cachedImages = getAllRequest.result;
                const validImages = cachedImages.filter(image => now - image.timestamp < 24 * 60 * 60 * 1000); // 24 heures

                if (validImages.length === 0) {
                    console.log('Pas d\'images en cache, démarrage du préchargement.');

                    fetch('/optimizedImages.json')
                        .then(response => response.json())
                        .then(data => {
                            const transaction = db.transaction('images', 'readwrite');
                            const store = transaction.objectStore('images');

                            // Sauvegarder les images dans IndexedDB
                            data.forEach(imageSrc => {
                                store.put({ src: imageSrc, timestamp: now });
                            });

                            console.log('Préchargement des images terminé.');
                        })
                        .catch(error => console.error('Erreur lors du préchargement :', error));
                } else {
                    console.log('Images déjà en cache.');
                }
            };
        };
    }, []);
};

export default useGlobalImagePreload;
