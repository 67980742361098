import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import { useEffect, useState } from 'react';

import HomePage from "./PagesT/HomePage";
import PWAPage from "./PagesT/PWAPage/PWAPage";
import AnalyticsPage from "./PagesT/AnalyticsPage/AnalyticsPage";
import VideoPage from "./PagesT/VideoPage/VideoPage";
import TrackingPage from "./PagesT/TrackingPage/TrackingPage";
import AlibabaPage from "./PagesT/AlibabaPage/AlibabaPage";
import ComputerPage from "./PagesT/ComputerPage/ComputerPage";
import NetworkPage from "./PagesT/NetworkPage/NetworkPage";
import ContactPage from "./PagesT/ContactPage/ContactPage";
import CVPage from "./PagesT/CVPage/CVPage";
import OfflineNotice from "./PagesT/OfflineNotice/OfflineNotice";

import BlogListTemplate from "./PagesT/BlogListTemplate/BlogListTemplate";
import BlogContentTemplate from "./PagesT/BlogContentTemplate/BlogContentTemplate";
import heroImage1 from "./PagesT/PWAPage/12.png";
import heroImage from "./PagesT/PWAPage/11.jpg";
import { blogs } from "./PagesT/BlogData/BlogData";

// Fonction utilitaire pour récupérer un blog par son ID


function App() {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    // Utiliser useEffect pour écouter l'événement beforeinstallprompt
    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e); // Sauvegarder l'événement
            setShowInstallPrompt(true); // Afficher le pop-up
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    // Fonction pour afficher l'invite d'installation
    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt(); // Afficher l'invite
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('L\'utilisateur a accepté l\'installation');
                } else {
                    console.log('L\'utilisateur a refusé l\'installation');
                }
                setDeferredPrompt(null);
                setShowInstallPrompt(false); // Fermer le pop-up
            });
        }
    };

    return (
        <HelmetProvider>
            <div className="App">

            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/pwa" element={<PWAPage />} />
                    <Route path="/analytics" element={<AnalyticsPage />} />
                    <Route path="/video" element={<VideoPage />} />
                    <Route path="/tracking" element={<TrackingPage />} />
                    <Route path="/alibaba" element={<AlibabaPage />} />
                    <Route path="/computer" element={<ComputerPage />} />
                    <Route path="/network" element={<NetworkPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/cv" element={<CVPage />} />

                    {/* Routes de blog avec gestion hors ligne */}
                    {/* Routes de blog avec gestion hors ligne */}
                    <Route
                        path="/blogs"
                        element={<>
                            <BlogListTemplate blogs={blogs} />
                            {!isOnline && <OfflineNotice />}
                        </>}
                    />
                    <Route
                        path="/blog/:id"
                        element={<>
                            <BlogContentTemplate />
                            {!isOnline && <OfflineNotice />}
                        </>}
                    />

                </Routes>
            </Router>

            {/* Pop-up modal pour l'installation */}
            {showInstallPrompt && (
                <div className="fixed inset-0 flex items-center justify-center z-50">

                    {/* Conteneur du modal avec glassmorphism */}
                    <div
                        className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg border border-white border-opacity-10 p-6 rounded-lg max-w-sm w-full"
                    >

                        {/* Logo Vytar au-dessus du titre */}
                        <div className="flex justify-center mb-4">
                            <img src="/512x512.png" alt="Vytar" className="w-32 h-32" />
                        </div>

                        <h2 className="text-lg font-semibold text-gray-900 mb-4">Installer Vytar</h2>
                        <p className="text-gray-700 mb-6">
                            Ajoutez cette application à votre écran d'accueil pour une expérience complète.
                        </p>

                        {/* Boutons centrés */}
                        <div className="flex justify-center space-x-4">
                            {/* Bouton Installer avec couleur verte */}
                            <button
                                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg"
                                onClick={handleInstallClick}
                                style={{ backgroundColor: '#34A853' }}  // Couleur verte personnalisée
                            >
                                Installer
                            </button>

                            {/* Bouton Annuler avec couleur rouge */}
                            <button
                                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg"
                                onClick={() => setShowInstallPrompt(false)}
                                style={{ backgroundColor: '#EA4335' }}  // Couleur rouge personnalisée
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            )}


        </div>
        </HelmetProvider>
    );
}





export default App;
