import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isRouteCached} from "../../utils/cacheUtils";

const OfflineNotice = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isRouteAvailable, setIsRouteAvailable] = useState(false);

    useEffect(() => {
        const checkRouteAvailability = async () => {
            const cached = await isRouteCached(location.pathname);
            setIsRouteAvailable(cached);
        };

        checkRouteAvailability();
    }, [location.pathname]);

    const handleRefresh = () => {
        if (navigator.onLine) {
            navigate(0);
        } else {
            alert("Vous êtes toujours hors ligne. Veuillez vérifier votre connexion internet.");
        }
    };

    if (isRouteAvailable) {
        return null; // Si la route est en cache, ne rien afficher
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
                <img src="/512x512.png" alt="Vytar" className="w-32 h-32 mx-auto mb-4" />
                <h2 className="text-2xl font-bold mb-4">Contenu non disponible hors ligne</h2>
                <p className="mb-6">
                    Ce contenu n'est pas encore disponible hors ligne. Pour y accéder,
                    veuillez vous connecter à Internet et visiter cette page une première fois.
                    Ensuite, elle sera accessible même sans connexion.
                </p>
                <button
                    onClick={handleRefresh}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    style={{ backgroundColor: '#4285F4' }}
                >
                    Actualiser
                </button>
            </div>
        </div>
    );
};

export default OfflineNotice;