import React from 'react';
import BlogReel from "./BlogReel";  // Assurez-vous que ce chemin d'importation est correct
import { Link } from 'react-router-dom';

const BlogReelCarousel = ({ blogs, spacing = 4 }) => {
    const spacingClasses = {
        1: 'gap-1',
        2: 'gap-2',
        3: 'gap-3',
        4: 'gap-4',
        5: 'gap-5',
        6: 'gap-6',
        8: 'gap-8',
        10: 'gap-10',
    };

    const gapClass = spacingClasses[spacing] || 'gap-4';

    // Limiter à 7 blogs
    const limitedBlogs = blogs.slice(0, 7);

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Nos derniers articles</h1> {/* Titre intégré */}
            <div className={`
              flex
              overflow-x-auto
              ${gapClass}
              scrollbar-thin
              scrollbar-thumb-[#4285F4]
              scrollbar-track-gray-200
              hover:scrollbar-thumb-[#3367D6]
              scrollbar-thumb-rounded-full
              pb-2
            `}>
                {limitedBlogs.map((blog, index) => (
                    <div key={index} className="flex-shrink-0">
                        <BlogReel
                            title={blog.title}
                            coverImage={blog.coverImage}
                            readMoreLink={`/blog/${blog.id}`}  // Assurez-vous que chaque blog a un id unique
                            onShare={() => console.log(`Partage du blog: ${blog.title}`)}
                            service={blog.service}
                        />
                    </div>
                ))}
            </div>
            <div className="mt-6 flex justify-center"> {/* Bouton ajouté */}
                <Link
                    to="/blogs"  // Redirige vers la page de tous les blogs
                    state={{ blogs }}  // Passe le tableau de blogs via le state
                    className="bg-[#4285F4] text-white px-4 py-2 text-xs rounded-md font-medium hover:bg-opacity-80 transition-colors"
                >
                    Tous nos articles
                </Link>
            </div>
        </div>
    );
};

export default BlogReelCarousel;
