// Vérifie si l'application est en mode développement local
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function register(config) {
    const isProduction = process.env.NODE_ENV === 'production';
    if ('serviceWorker' in navigator) {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        window.addEventListener('load', () => {
            console.log(`[ServiceWorkerRegistration] Application chargée, tentative d'enregistrement du Service Worker (Environnement: ${isProduction ? 'Production' : 'Développement'})`);
            if (isLocalhost) {
                checkValidServiceWorker(swUrl, config);
            } else {
                registerValidSW(swUrl, config);
            }
        });
    } else {
        console.log('[ServiceWorkerRegistration] Service Workers non supportés dans ce navigateur');
    }
}

function registerValidSW(swUrl, config) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            console.log('[ServiceWorkerRegistration] Service Worker enregistré avec succès');

            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            console.log('[ServiceWorkerRegistration] Nouveau contenu disponible. Activation automatique...');
                            if (registration.waiting) {
                                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                            }
                        } else {
                            console.log('[ServiceWorkerRegistration] Contenu mis en cache pour une utilisation hors ligne');
                        }
                    }
                };
            };
        })
        .catch(error => {
            console.error('[ServiceWorkerRegistration] Erreur lors de l_enregistrement du Service Worker:', error);
        });

    // Gestion du rechargement automatique après l'activation d'un nouveau Service Worker
    let refreshing = false;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (!refreshing) {
            console.log('[ServiceWorkerRegistration] Nouveau Service Worker activé. Rechargement de la page...');
            refreshing = true;
            window.location.reload();
        }
    });
}

function checkValidServiceWorker(swUrl, config) {
    fetch(swUrl, {
        headers: { 'Service-Worker': 'script' },
    })
        .then(response => {
            const contentType = response.headers.get('content-type');
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf('javascript') === -1)
            ) {
                console.log('[ServiceWorkerRegistration] Aucun Service Worker trouvé. Nettoyage et rechargement...');
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                console.log('[ServiceWorkerRegistration] Service Worker valide trouvé. Procédure d_enregistrement standard.');
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log('[ServiceWorkerRegistration] Aucune connexion internet trouvée. L_application fonctionne en mode hors ligne.');
        });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then(registration => {
                registration.unregister();
                console.log('[ServiceWorkerRegistration] Service Worker désinscrit avec succès');
            })
            .catch(error => {
                console.error('[ServiceWorkerRegistration] Erreur lors de la désinscription du Service Worker:', error);
            });
    }
}