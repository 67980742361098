import React , { useEffect , useState  } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import useGlobalImagePreload from "./useGlobalImagePreload";
//import imagesToPreload from '../../public/optimizedImages.json';
import PwaIcon from '../assets/pwa-multiplatform-icon-final.svg';
import AnalyticsIcon from '../assets/data-analysis-icon-with-shadow.svg';
import VideoIcon from '../assets/video-surveillance-icon.svg';
import TrackingIcon from '../assets/adjusted-gps-tracking-icon.svg';
import AlibabaIcon from '../assets/alibaba-commerce-icon-centered.svg';
import ComputerIcon from '../assets/computer-laptop-sales-icon.svg';
import NetworkIcon from '../assets/satellite-internet-icon.svg';
import ContactIcon from '../assets/contact-icon-equal-height.svg';
import CvIcon from '../assets/cv-icon.svg';
import {blogs} from "./BlogData/BlogData";
import BlogReelCarousel from "./BlogReelCarousel";




const menuItems = [
    { name: 'PWA', icon: PwaIcon, path: '/pwa', alt: 'Développement PWA pour Android et PC' },
    { name: 'ANALYTICS', icon: AnalyticsIcon, path: '/analytics', alt: 'Analyse de données' },
    { name: 'VIDEO', icon: VideoIcon, path: '/video', alt: 'Installation de caméras de surveillance' },
    { name: 'TRACKING', icon: TrackingIcon, path: '/tracking', alt: 'Installation et suivi GPS' },
    { name: 'ALIBABA', icon: AlibabaIcon, path: '/alibaba', alt: 'Accompagnement Alibaba' },
    { name: 'COMPUTER', icon: ComputerIcon, path: '/computer', alt: 'Vente et maintenance de PC' },
    { name: 'NETWORK', icon: NetworkIcon, path: '/network', alt: 'Connexion satellite avec Strat Link' },
    { name: 'CONTACT', icon: ContactIcon, path: '/contact', alt: 'Contactez-nous' },
    { name: 'CV', icon: CvIcon, path: '/cv', alt: 'Envoyez-nous votre CV' },
];

const HomePage = () => {
    const PWA = blogs.filter(blog => blog.service === "PWA");
   /* const [imagesToPreload, setImagesToPreload] = useState([]);
    useEffect(() => {
        const cachedImages = localStorage.getItem('imagesToPreload');
        if (cachedImages) {
            // Si les images sont dans le cache, ne pas recharger.
            console.log("Chargement des images depuis le cache.");
            setImagesToPreload(JSON.parse(cachedImages));
        } else {
            // Si les images ne sont pas dans le cache, les charger et les stocker.
            fetch('/optimizedImages.json')
                .then(response => response.json())
                .then(data => {
                    console.log('Images à précharger :', data);
                    setImagesToPreload(data);
                    localStorage.setItem('imagesToPreload', JSON.stringify(data));
                })
                .catch(error => console.error('Erreur lors du chargement des images :', error));
        }
    }, []);*/

    useGlobalImagePreload(); // Précharge les images


    return (
        <HelmetProvider>
            <div className="flex flex-col min-h-screen bg-white items-center">
                {/* Optimisation SEO avec Helmet */}
                <Helmet>
                    <title>Vytar - Experts en PWA, GPS, analyse de données et plus</title>
                    <meta name="description" content="Vytar propose des services en développement web (PWA), tracking GPS, analyse de données, vente de PC et installation de caméras de surveillance."/>
                    <meta name="keywords" content="PWA, analyse de données, tracking GPS, vente de PC, installation caméras, Strat Link, Alibaba" />
                    <meta name="robots" content="index, follow" />

                    {/* Open Graph pour réseaux sociaux */}
                    <meta property="og:title" content="Vytar - Votre partenaire en web, tracking et analyse de données" />
                    <meta property="og:description" content="Découvrez les services de Vytar : développement PWA, analyse de données, tracking GPS, et plus encore." />
                    <meta property="og:image" content="/assets/512x512.png" />
                    <meta property="og:url" content="https://vytar-8e850.web.app" />
                    <meta property="og:type" content="website" />

                    {/* Twitter Cards */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Vytar - Experts en PWA, GPS, analyse de données" />
                    <meta name="twitter:description" content="Vytar propose des services variés, du développement web au tracking GPS." />
                    <meta name="twitter:image" content="/assets/512x512.png" />

                    <link rel="manifest" href="/manifest.json" />
                    <meta name="theme-color" content="#0a0a2a" />
                    <link href="https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@400&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="w-full flex justify-center mt-1 mb-6">
                    <div className="w-64 flex flex-col items-center justify-center bg-white p-4">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 75" className="w-full h-100">
                            <defs>
                                <filter id="textShadow" x="-10%" y="-10%" width="120%" height="120%">
                                    <feDropShadow dx="1" dy="1" stdDeviation="1" floodOpacity="0.3"/>
                                </filter>
                            </defs>
                            <text x="100" y="50" fontFamily="SF Pro Rounded, Arial, sans-serif" fontWeight="300"
                                  letterSpacing="-1" filter="url(#textShadow)" textAnchor="middle" dominantBaseline="middle">
                                <tspan fontSize="60" fill="#34A853">V</tspan>
                                <tspan fontSize="50" fill="#4285F4">y</tspan>
                                <tspan fontSize="50" fill="#EA4335">t</tspan>
                                <tspan fontSize="50" fill="#4285F4">a</tspan>
                                <tspan fontSize="50" fill="#FBBC05">r</tspan>
                            </text>
                        </svg>
                        <div className="mt-0.5 text-sm text-gray-600" style={{fontFamily: 'SF Pro Display, Arial, sans-serif'}}>
                            une vision smart
                        </div>
                    </div>
                </div>


                <section className="mt-8 flex justify-center items-center w-full max-w-4xl px-4">
                    <div className="grid grid-cols-3 gap-x-4 gap-y-8 sm:gap-x-8 md:gap-x-16 md:gap-y-16">
                        {menuItems.map((item, index) => (
                            <Link to={item.path} key={index} className="flex flex-col items-center justify-center cursor-pointer transition-all duration-300 hover:scale-105 w-24 h-24 sm:w-32 sm:h-32 md:w-36 md:h-36 rounded-xl hover:bg-gray-100 hover:shadow-lg p-2 sm:p-4">
                                <img src={item.icon} alt={item.alt} className="w-12 h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 mb-1 sm:mb-2"/>
                                <div className="text-xs sm:text-sm text-black text-center" style={{ fontFamily: 'SF Pro Display, Arial, sans-serif' }}>{item.name}</div>
                            </Link>
                        ))}
                    </div>
                </section>

                {/* Blog Section */}

                <BlogReelCarousel blogs={PWA} spacing={7}/>


            </div>
        </HelmetProvider>
    );
};

export default HomePage;
