import React, {useRef} from 'react';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import heroImage from '../PWAPage/9.jpg'
import logoImage from '../PWAPage/192x192.png';
import OptimizedImage from "../../components/OptimizedImage";
import NavigationBar from "../../components/NavigationBar";
import {
    Battery,
    Truck,
    Navigation,
    Shield,
    Bluetooth,
    HardDrive,
    Wifi,
    Bell,
    Smartphone,
    Zap,
    Droplet,
    CloudRain,
    Cpu,
    Layers,
    AlertTriangle,
    Activity,
    MapPin,
    BarChart,
    Users,
    Code,
    Share2,
    Cloud,
    Clock,
    Settings
} from 'lucide-react';


import {Card, CardContent} from '../../components/ui/card';


const gpsCharacteristics = [
    {
        name: "Teltonika FMB204",
        features: [
            {
                icon: Battery,
                color: "text-green-500",
                name: "Batterie",
                description: "Li-ion 1800 mAh, autonomie jusqu'à 6 jours en mode économie d'énergie"
            },
            {
                icon: Navigation,
                color: "text-blue-500",
                name: "Positionnement",
                description: "GPS, GLONASS, GALILEO, BEIDOU, précision jusqu'à 2,5m, sensibilité de suivi -165 dBm"
            },
            {
                icon: Shield,
                color: "text-yellow-500",
                name: "Conception",
                description: "IP67, résistant à l'eau, à la poussière et aux environnements difficiles"
            },
            {
                icon: Bluetooth,
                color: "text-indigo-500",
                name: "Connectivité",
                description: "Bluetooth 4.0, USB 2.0 Micro-USB pour configuration et mises à jour"
            },
            {
                icon: HardDrive,
                color: "text-purple-500",
                name: "Stockage",
                description: "128 Mo de mémoire interne pour stockage des données hors connexion"
            },
            {
                icon: Layers,
                color: "text-orange-500",
                name: "Interfaces",
                description: "3 entrées numériques, 2 sorties numériques, 1 entrée analogique, support capteurs LLS"
            },
            {
                icon: Droplet,
                color: "text-blue-500",
                name: "Surveillance carburant",
                description: "Intégration directe avec OBDII, pas de calibration manuelle nécessaire"
            },
            {
                icon: Zap,
                color: "text-yellow-500",
                name: "Fonctionnalités avancées",
                description: "Mise à jour firmware à distance (FOTA), détection d'excès de vitesse, conduite écologique, détection de remorquage, géorepérage dynamique"
            },
            {
                icon: Cpu,
                color: "text-red-500",
                name: "Scénarios d'utilisation",
                description: "Gestion de flotte, suivi en environnements difficiles, surveillance de précision"
            }
        ]
    },
    {
        name: "Micodus MV710",
        features: [
            {
                icon: Battery,
                color: "text-red-500",
                name: "Batterie",
                description: "280 mAh, autonomie limitée en mode batterie interne"
            },
            {
                icon: Navigation,
                color: "text-blue-500",
                name: "Positionnement",
                description: "GPS et AGPS, précision moyenne de 5 à 10 mètres"
            },
            {
                icon: Shield,
                color: "text-gray-500",
                name: "Conception",
                description: "Boîtier compact et discret, sans certification IP"
            },
            {
                icon: Wifi,
                color: "text-green-500",
                name: "Connectivité",
                description: "GPRS pour transmission de données en temps réel, sans Bluetooth"
            },
            {
                icon: Layers,
                color: "text-orange-500",
                name: "Interfaces",
                description: "Moins d'entrées/sorties, axé sur des usages basiques"
            },
            {
                icon: Droplet,
                color: "text-blue-500",
                name: "Surveillance carburant",
                description: "Nécessite une calibration manuelle kilométrique"
            },
            {
                icon: Bell,
                color: "text-yellow-500",
                name: "Fonctionnalités d'alarme",
                description: "Alarmes pour mouvement, vibrations, remorquage, accélération brusque, géorepérage"
            },
            {
                icon: Truck,
                color: "text-purple-500",
                name: "Usage",
                description: "Applications basiques de suivi de véhicules, détection de chocs et démarrages moteurs"
            }
        ]
    }
];

const hostingPlatforms = [
    {
        name: "GPS Serveur",
        features: [
            {
                icon: MapPin,
                color: "text-red-500",
                name: "Suivi en temps réel",
                description: "Interface cartographique détaillée, accessible sur navigateur et mobile"
            },
            {
                icon: BarChart,
                color: "text-blue-500",
                name: "Rapports personnalisables",
                description: "Rapports détaillés sur arrêts, trajets, consommation de carburant"
            },
            {
                icon: Droplet,
                color: "text-green-500",
                name: "Surveillance du carburant",
                description: "Surveillance de la consommation avec alertes pour anomalies"
            },
            {
                icon: Bell,
                color: "text-yellow-500",
                name: "Alertes personnalisables",
                description: "Configurable pour excès de vitesse, arrêts brusques, sorties de zones, etc."
            },
            {
                icon: Users,
                color: "text-purple-500",
                name: "Gestion multi-utilisateurs",
                description: "Accès multiples avec niveaux de permissions distincts"
            },
            {
                icon: Smartphone,
                color: "text-indigo-500",
                name: "Support multi-GPS",
                description: "Compatible avec plusieurs modèles dont Teltonika et Micodus"
            }
        ]
    },
    {
        name: "WanWay Track (Open Source)",
        features: [
            {
                icon: Code,
                color: "text-purple-500",
                name: "Plateforme Open Source",
                description: "Personnalisable selon les besoins spécifiques"
            },
            {
                icon: MapPin,
                color: "text-red-500",
                name: "Suivi en temps réel",
                description: "Visualisation des véhicules sur carte interactive avec vitesse"
            },
            {
                icon: BarChart,
                color: "text-blue-500",
                name: "Rapports de performance",
                description: "Détails sur distance, temps d'arrêt, consommation de carburant"
            },
            {
                icon: Shield,
                color: "text-yellow-500",
                name: "Géorepérage avancé",
                description: "Zones personnalisées avec alertes automatiques"
            },
            {
                icon: Users,
                color: "text-green-500",
                name: "Gestion de flotte",
                description: "Gestion de plusieurs véhicules avec rôles et permissions"
            },
            {
                icon: Share2,
                color: "text-indigo-500",
                name: "Intégration d'applications tierces",
                description: "API ouverte pour intégration avec d'autres logiciels"
            }
        ]
    },
    {
        name: "Micodus",
        features: [
            {
                icon: MapPin,
                color: "text-red-500",
                name: "Suivi en temps réel",
                description: "Localisation via application mobile ou interface web"
            },
            {
                icon: Droplet,
                color: "text-blue-500",
                name: "Surveillance du carburant",
                description: "Rapports basés sur les données de calibrage des GPS Micodus"
            },
            {
                icon: Shield,
                color: "text-yellow-500",
                name: "Géorepérage et alertes",
                description: "Création de zones avec notifications, alertes vitesse et arrêts"
            },
            {
                icon: Cloud,
                color: "text-purple-500",
                name: "Sauvegarde cloud",
                description: "Stockage des données avec historique sur plusieurs mois"
            },
            {
                icon: Smartphone,
                color: "text-green-500",
                name: "Application mobile",
                description: "Suivi des actifs et gestion des alertes en mobilité"
            },
            {
                icon: Settings,
                color: "text-gray-500",
                name: "Utilisation simplifiée",
                description: "Conçu pour une utilisation simple et rapide, idéal pour petits utilisateurs"
            }
        ]
    }
];


const TrakingPage = () => {
    const navigate = useNavigate();
    const featuresRef = useRef(null);

    const handleContactClick = () => {
        navigate('/contact');
    };

    const handleStartClick = () => {
        featuresRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    return (
        <div className="bg-white text-gray-800 min-h-screen">
            <Helmet>
                <title>Suivi GPS et Gestion de Flotte | Optimisation Totale</title>
                <meta name="description"
                      content="Solution complète de tracking GPS pour véhicules et équipements. Optimisez vos actifs avec nos systèmes GPS fiables et nos plateformes de suivi en temps réel."/>
                <meta name="keywords"
                      content="installation GPS, suivi de flotte, tracking en temps réel, optimisation des itinéraires, surveillance carburant"/>
                <meta property="og:title" content="Suivi GPS et Gestion de Flotte | Optimisation Totale"/>
                <meta property="og:description"
                      content="Optimisez votre flotte avec notre solution de tracking GPS. Suivi en temps réel, gestion de carburant et rapports détaillés pour une efficacité maximale."/>
                <meta property="og:image" content={heroImage}/>
                <meta property="og:url" content={window.location.href}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <NavigationBar/>
            <section className="relative h-screen flex flex-col items-center justify-center overflow-hidden">
                {/* Image de fond */}
                <div className="absolute inset-0 z-0">
                    <OptimizedImage
                        src={heroImage}  // Utilise l'image importée
                        alt="Background"
                        className="absolute inset-0 w-full h-full object-cover"
                        sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 100vw"
                        style={{display: 'block', width: '100%', height: '100%'}}
                    />
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                {/* Logo */}
                <motion.img
                    src={logoImage}
                    alt="Logo"
                    className="w-32 h-32 mb-8 relative z-10"
                    initial={{opacity: 0, y: -50}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                />

                {/* Contenu */}
                <div className="relative z-10 text-center px-4">
                    <motion.h1
                        className="text-5xl md:text-7xl font-bold text-white mb-4"
                        initial={{opacity: 0, y: -50}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5, delay: 0.2}}
                    >
                        Suivi GPS et Gestion de Flotte
                    </motion.h1>

                    <motion.button
                        className="mt-8 px-8 py-3 bg-white text-blue-500 rounded-lg font-bold hover:bg-blue-100 transition duration-300"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay: 0.4, duration: 0.5}}
                        onClick={handleStartClick}
                    >
                        Découvrir
                    </motion.button>
                </div>
            </section>

            {/* Section Introduction */}
            <section ref={featuresRef} className="py-20 px-4">
                <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-3xl font-bold mb-6">Solution Complète de Tracking GPS</h2>
                    <p className="text-lg text-gray-600 mb-8">
                        Notre service de tracking GPS propose une solution complète pour suivre et optimiser vos actifs,
                        qu'il s'agisse de véhicules, d'équipements ou de marchandises. Nous installons des systèmes GPS
                        fiables tels que les modèles Micodus MV730 et Teltonika FMB204, tout en traitant les données
                        collectées pour offrir des rapports précis et détaillés.
                    </p>
                </div>
            </section>

            {/* Section Caractéristiques GPS */}
            <section className="py-16 bg-gray-100">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">Caractéristiques de nos GPS</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {gpsCharacteristics.map((gps, index) => (
                            <Card key={index} className="overflow-hidden">
                                <CardContent className="p-6">
                                    <h3 className="text-2xl font-semibold mb-6 text-center">{gps.name}</h3>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        {gps.features.map((feature, featureIndex) => (
                                            <Card key={featureIndex}
                                                  className="bg-white shadow-sm hover:shadow-md transition-shadow duration-300">
                                                <CardContent className="p-4">
                                                    <div className="flex items-center mb-2">
                                                        <feature.icon className={`w-6 h-6 ${feature.color} mr-2`}/>
                                                        <h4 className="font-semibold text-lg">{feature.name}</h4>
                                                    </div>
                                                    <p className="text-sm text-gray-600 text-left leading-loose">{feature.description}</p>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>

            {/* Section Fonctionnalités */}
            <section className="py-20 px-4">
                <div className="max-w-6xl mx-auto">
                    <h2 className="text-3xl font-bold text-center mb-12">Fonctionnalités Clés</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="text-center">
                            <MapPin className="w-12 h-12 text-blue-500 mx-auto mb-4"/>
                            <h3 className="text-xl font-semibold mb-2">Suivi en temps réel</h3>
                            <p>Visibilité constante des mouvements de vos véhicules 24/7</p>
                        </div>
                        <div className="text-center">
                            <AlertTriangle className="w-12 h-12 text-yellow-500 mx-auto mb-4"/>
                            <h3 className="text-xl font-semibold mb-2">Notifications d'événements</h3>
                            <p>Alertes pour les comportements de conduite non sécurisés</p>
                        </div>
                        <div className="text-center">
                            <BarChart className="w-12 h-12 text-green-500 mx-auto mb-4"/>
                            <h3 className="text-xl font-semibold mb-2">Rapports détaillés</h3>
                            <p>Analyses de consommation de carburant et d'efficacité des trajets</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Section Plateformes d'hébergement */}
            <section className="py-16 bg-gray-100">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">Plateformes d'hébergement GPS</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                        {hostingPlatforms.map((platform, index) => (
                            <Card key={index} className="overflow-hidden">
                                <CardContent className="p-6">
                                    <h3 className="text-2xl font-semibold mb-6 text-center">{platform.name}</h3>
                                    <div className="grid grid-cols-1 gap-4">
                                        {platform.features.map((feature, featureIndex) => (
                                            <Card key={featureIndex}
                                                  className="bg-white shadow-sm hover:shadow-md transition-shadow duration-300">
                                                <CardContent className="p-4">
                                                    <div className="flex items-center mb-2">
                                                        <feature.icon className={`w-6 h-6 ${feature.color} mr-2`}/>
                                                        <h4 className="font-semibold text-lg">{feature.name}</h4>
                                                    </div>
                                                    <p className="text-sm text-gray-600 text-left leading-loose">{feature.description}</p>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>


            {/* FOOTER Section */}
            <section className="relative h-[330px] px-4 text-white overflow-hidden">
                {/* Image de fond coupée */}
                <div className="absolute inset-0 z-0">
                    <OptimizedImage
                        src={heroImage}  // Utilise l'image importée
                        alt="Background"
                        className="absolute inset-0 w-full h-full object-cover object-center"
                        sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 100vw"
                        style={{
                            transform: 'scale(1.5)',
                            transformOrigin: 'center bottom',
                            display: 'block',
                            width: '100%',
                            height: '100%'
                        }}
                    />
                    {/* Ajout de l'effet sombre */}
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <div className="relative z-10 max-w-4xl mx-auto text-center pt-10">
                    <h2 className="text-4xl font-bold mb-6">Prêt à commencer ?</h2>
                    <p className="text-xl mb-8">
                        Contactez-nous dès aujourd'hui pour discuter de votre prochain projet.
                    </p>
                    <button
                        onClick={handleContactClick}
                        className="px-8 py-3 rounded-lg font-bold text-white bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg hover:bg-opacity-30 transition duration-300"
                    >
                        Commencez votre projet
                    </button>
                </div>
            </section>

        </div>
    );
};

export default TrakingPage;
