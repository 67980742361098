import React, { useRef ,useEffect} from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../../components/ui/card';
import { Badge } from '../../components/ui/badge';
import {blogs} from "../BlogData/BlogData";
import OptimizedImage from "../../components/OptimizedImage";


import {BookOpen, Code, Globe, Palette, Cog, PenTool, TrendingUp, Users , ShoppingCart,Warehouse,Building,Truck,Plane } from 'lucide-react';

import heroImage from './4.jpg'
import logoImage from './192x192.png';
import htmlImage from './HTML.png';
import cssImage from './tailwindcss.png';
import jsImage from './javascript.png';
import reactImage from './react.png';
import pythonImage from './python.png';
import djangoImage from './django.png';
import postgresqlImage from './postgresql.png';

import webStormImage from './WebStorm.png';
import pyCharmImage from './Pycharm.png';
import dataGripImage from './Datagrip.png';
import NavigationBar from "../../components/NavigationBar";
import BlogReelCarousel from "../BlogReelCarousel";

const PWAPage = () => {
    // Scroll to top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const PWA = blogs.filter(blog => blog.service === "PWA");
    const navigate = useNavigate();

    const handleContactClick = () => {
        navigate('/contact'); // Assurez-vous que cette route existe dans votre configuration
    };
    const featuresRef = useRef(null);
    const handleStartClick = () => {
        featuresRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const servicesRef = useRef(null);

    const scrollToServices = () => {
        servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const services = [
        {
            name: "Développement de site web PWA",
            icon: <Globe className="w-8 h-8 text-blue-500" />,
            description: "Créez une présence en ligne captivante et accessible hors ligne grâce à nos fonctionnalités PWA personnalisés. Profitez d'un design moderne, de fonctionnalités avancées et d'une optimisation pour tous les appareils. Nos PWA offrent une expérience utilisateur fluide, des temps de chargement rapides et la possibilité d'installer le site comme une application, augmentant ainsi l'engagement et la fidélisation des utilisateurs."
        },
        {
            name: "Développement d'applications multiplateformes",
            icon: <Code className="w-8 h-8 text-purple-500" />,
            description: "Maximisez votre portée avec nos applications multiplateformes performantes. Développées une seule fois, elles fonctionnent parfaitement sur iOS, Android et le Web, réduisant les coûts de développement et de maintenance. Profitez d'une expérience utilisateur cohérente sur tous les appareils, d'une mise sur le marché plus rapide et d'une facilité de mise à jour. Idéal pour les entreprises cherchant à toucher un large public avec une solution technologique unifiée et évolutive."
        },
        {
            name: "Rédaction web",
            icon: <PenTool className="w-8 h-8 text-green-500" />,
            description: "Captivez votre audience avec un contenu web persuasif et optimisé SEO, conçu pour engager vos lecteurs et stimuler la conversion de vos visiteurs en clients fidèles."
        },
        {
            name: "SEO",
            icon: <TrendingUp className="w-8 h-8 text-red-500" />,
            description: "Propulsez votre visibilité en ligne avec nos stratégies SEO avancées, conçues pour augmenter votre classement dans les moteurs de recherche et attirer un trafic qualifié."
        },
        {
            name: "Community manager",
            icon: <Users className="w-8 h-8 text-yellow-500" />,
            description: "Dynamisez votre présence en ligne sur les réseaux sociaux avec notre gestion de communauté experte, créant un engagement fort et une relation durable avec votre audience."
        },
        {
            name: "Maintenance",
            icon: <Cog className="w-8 h-8 text-gray-500" />,
            description: "Garantissez la performance, la sécurité et la mise à jour continue de votre site web avec notre service de maintenance proactif, assurant une expérience utilisateur optimale."
        },
        {
            name: "Design Graphique",
            icon: <Palette className="w-8 h-8 text-pink-500" />,
            description: "Créez une identité visuelle mémorable avec nos designs graphiques innovants, adaptés à votre marque et conçus pour captiver votre public cible sur tous les supports."
        },
        {
            name: "Formation",
            icon: <BookOpen className="w-8 h-8 text-indigo-500" />,
            description: "Développez vos compétences avec nos formations expertes en développement web, IA, logiciels bureautiques et nouvelles technologies, adaptées à tous les niveaux."
        }
    ];

    const sectors = [
        { name: "Commerce", icon: ShoppingCart, color: "text-red-500" },
        { name: "Dépôt & Entrepôt", icon: Warehouse, color: "text-yellow-500" },
        { name: "Finance", icon: Building, color: "text-green-500" },
        { name: "Transport & Logistique", icon: Truck, color: "text-blue-500" },
        { name: "Tourisme", icon: Plane, color: "text-purple-500" }
    ];

    const technologies = [
        { name: "HTML", image: htmlImage },
        { name: "CSS", image: cssImage },
        { name: "JAVASCRIPT", image: jsImage },
        { name: "REACT", image: reactImage },
        { name: "PYTHON", image: pythonImage },
        { name: "DJANGO", image: djangoImage },
        { name: "POSTGRESQL", image: postgresqlImage },
    ];

    const software = [
        {
            name: "WebStorm",
            image:webStormImage,
            description: "IDE puissant pour le développement web moderne, offrant une prise en charge avancée de JavaScript, TypeScript et frameworks populaires. Sous licence, WebStorm fournit des outils d'analyse de code, de débogage et d'intégration continue, accélérant le développement et améliorant la qualité du code."
        },
        {
            name: "PyCharm",
            image:pyCharmImage ,
            description: "Environnement de développement intégré conçu pour Python, avec support pour le développement web et scientifique. La licence PyCharm offre des fonctionnalités avancées telles que le profilage de code, la gestion de base de données et l'intégration de frameworks, optimisant la productivité des développeurs Python."
        },
        {
            name: "DataGrip",
            image: dataGripImage,
            description: "Outil de gestion de base de données multi-moteur, idéal pour PostgreSQL et NoSQL. Sous licence, DataGrip propose des fonctionnalités avancées de requête, de visualisation de données et de contrôle de version, facilitant la manipulation et l'analyse des données pour les développeurs et les administrateurs de bases de données."
        }
    ];

    //const pwaBlogs = blogs.filter(blog => blog.service === "PWA");

    return (
        <div className="bg-white text-gray-800 min-h-screen">
            <NavigationBar/>
            {/* Hero Section */}
            <section className="relative h-screen flex flex-col items-center justify-center overflow-hidden">
                {/* Image de fond */}
                <div className="absolute inset-0 z-0">
                    <OptimizedImage
                        src={heroImage}  // Utilise l'image importée
                        alt="Hero background"
                        className="absolute inset-0 w-full h-full object-cover"  // Positionnement absolu et full cover
                        sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 100vw"
                        style={{display: 'block', width: '100%', height: '100%'}}
                    />
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                {/* Logo */}
                <motion.img
                    src={logoImage}
                    alt="Logo"
                    className="w-32 h-32 mb-8 relative z-10"
                    initial={{opacity: 0, y: -50}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                />

                {/* Contenu */}
                <div className="relative z-10 text-center px-4">
                    <motion.div
                        className="mb-6"
                        initial={{opacity: 0, y: -50}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5, delay: 0.2}}
                    >
                        <h1 className="text-5xl md:text-7xl font-bold text-white">
                            <span className="block mb-4">Développement</span>
                            <span className="block"> PWA</span>
                        </h1>
                    </motion.div>


                    <motion.button
                        className="mt-8 px-8 py-3 bg-white text-blue-500 rounded-lg font-bold hover:bg-blue-100 transition duration-300"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay: 0.4, duration: 0.5}}
                        onClick={handleStartClick}
                    >
                        Découvrir
                    </motion.button>
                </div>
            </section>


            {/* Services Section */}
            <section ref={featuresRef} className="py-20 px-4">
                <h2 className="text-4xl font-bold text-center mb-12">Nos Services</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
                    {services.map((service, index) => (
                        <motion.div
                            key={index}
                            initial={{opacity: 0, y: 50}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: index * 0.1}}
                            className="h-full" // Assurez-vous que le conteneur de motion prend toute la hauteur
                        >
                            <Card className="hover:shadow-xl transition duration-300 h-full flex flex-col">
                                <CardContent className="p-6 flex flex-col flex-grow">
                                    <div className="flex items-center mb-4">
                                        {service.icon}
                                        <h3 className="text-xl font-semibold ml-4">{service.name}</h3>
                                    </div>
                                    <p className="text-gray-600 flex-grow text-left leading-loose">{service.description}</p>
                                </CardContent>
                            </Card>
                        </motion.div>
                    ))}
                </div>
            </section>

            {/* Sectors Section */}
            <section className="py-20 px-4 bg-gray-100">
                <h2 className="text-4xl font-bold text-center mb-12">Secteurs d'activité</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 max-w-6xl mx-auto">
                    {sectors.map((sector, index) => (
                        <motion.div
                            key={index}
                            initial={{opacity: 0, y: 50}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: index * 0.1}}
                        >
                            <div
                                className="bg-gray-100 rounded-xl p-6 shadow-[inset_-5px_-5px_10px_rgba(255,255,255,0.8),inset_5px_5px_10px_rgba(0,0,0,0.1)] hover:shadow-[inset_-1px_-1px_5px_rgba(255,255,255,0.6),inset_1px_1px_5px_rgba(0,0,0,0.1),5px_5px_10px_rgba(0,0,0,0.1),-5px_-5px_10px_rgba(255,255,255,0.8)] transition duration-300 flex flex-col items-center justify-center h-40">
                                <sector.icon className={`w-12 h-12 mb-4 ${sector.color}`}/>
                                <span className="text-center font-semibold">{sector.name}</span>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </section>

            {/* Technologies Section */}
            <section className="py-20 px-4">
                <h2 className="text-4xl font-bold text-center mb-12">Technologies Utilisées</h2>
                <div className="flex flex-wrap justify-center items-start gap-8 max-w-6xl mx-auto">
                    {technologies.map((tech, index) => (
                        <motion.div
                            key={index}
                            className="flex flex-col items-center w-32"
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: index * 0.1}}
                        >
                            <img
                                src={tech.image}
                                alt={tech.name}
                                className="w-16 h-16 object-contain mb-4"
                            />
                            <Badge variant="secondary" className="px-3 py-1 text-sm whitespace-nowrap">
                                {tech.name}
                            </Badge>
                        </motion.div>
                    ))}
                </div>
            </section>

            {/* Licensed Software Section */}
            <section className="py-20 px-4 bg-gray-900">
                <h2 className="text-4xl font-bold text-center mb-12 text-white">Logiciels sous Licence</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
                    {software.map((sw, index) => (
                        <motion.div
                            key={index}
                            initial={{opacity: 0, y: 50}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: index * 0.1, duration: 0.5}}
                        >
                            <div className="bg-gray-900 rounded-[11px] p-6 h-full
                            shadow-[5px_5px_7px_#151719,-5px_-5px_7px_#252a2e]
                            hover:shadow-[inset_5px_5px_7px_#151719,inset_-5px_-5px_7px_#252a2e]
                            transition duration-300">
                                <div className="flex flex-col items-center text-center">
                                    <img
                                        src={sw.image}
                                        alt={sw.name}
                                        className="w-24 h-24 object-contain mb-4"
                                        onError={(e) => {
                                            console.error(`Error loading image for ${sw.name}`);
                                            e.target.style.display = 'none';
                                        }}
                                    />
                                    <h3 className="text-xl font-semibold mb-2 text-white">{sw.name}</h3>
                                    <p className="text-gray-300 text-left mb-2 mt-2 leading-loose">{sw.description}</p>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </section>

            {/* Blog Section */}

            <BlogReelCarousel blogs={PWA} spacing={7}/>

            {/* CTA Section */}
            <section className="relative h-[330px] px-4 text-white overflow-hidden">
                {/* Image de fond coupée */}
                <div className="absolute inset-0 z-0">
                    <OptimizedImage
                        src={heroImage}  // Utilise l'image importée
                        alt="Background"
                        className="absolute inset-0 w-full h-full object-cover object-center"
                        sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 100vw"
                        style={{
                            transform: 'scale(1.5)',
                            transformOrigin: 'center bottom',
                            display: 'block',
                            width: '100%',
                            height: '100%'
                        }}
                    />
                    {/* Ajout de l'effet sombre */}
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <div className="relative z-10 max-w-4xl mx-auto text-center pt-10">
                    <h2 className="text-4xl font-bold mb-6">Prêt à commencer ?</h2>
                    <p className="text-xl mb-8">
                        Contactez-nous dès aujourd'hui pour discuter de votre prochain projet.
                    </p>
                    <button
                        onClick={handleContactClick}
                        className="px-8 py-3 rounded-lg font-bold text-white bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg hover:bg-opacity-30 transition duration-300"
                    >
                        Commencez votre projet
                    </button>
                </div>
            </section>

        </div>
    );
};

export default PWAPage;