import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Fonction pour générer le chemin du fichier webp
const getWebpPath = (src) => {
    const baseFileName = src.split('/').pop().split('.')[0];  // Obtenir '4'
    return `/optimized/${baseFileName}.webp`;  // Chemin vers le fichier .webp d'origine
};

// Fonction pour générer le srcSet avec un fallback pour les grands écrans
const generateDynamicSrcSet = (src, sizes) => {
    const baseFileName = src.split('/').pop().split('.')[0];  // Obtenir '4'
    const optimizedPath = `/optimized/`;  // Chemin vers les fichiers optimisés
    const srcSet = sizes
        .map(size => `${optimizedPath}${baseFileName}_${size}.webp ${size}w`)  // Ex: /optimized/4_300.webp 300w
        .join(', ');

    // Ajouter l'image originale pour les très grands écrans
    return `${srcSet}, ${optimizedPath}${baseFileName}.webp 2000w`;  // Image originale pour les grands écrans (> 1800px)
};

const OptimizedImage = ({ src, alt, className, sizes = "100vw", style, ...props }) => {
    const imageWebp = getWebpPath(src);  // Chemin vers l'image .webp
    const srcSetSizes = [300, 600, 1200, 1800];  // Les tailles disponibles pour srcSet

    useEffect(() => {
        //console.log("Image source (src):", imageWebp);  // Log du chemin .webp d'origine
        //console.log("Image srcSet:", generateDynamicSrcSet(src, srcSetSizes));  // Log du srcSet généré
        //console.log("Image sizes:", sizes);  // Log des tailles d'affichage
    }, [imageWebp, sizes, src]);

    return (
        <LazyLoadImage
            alt={alt}
            effect="null"  // Effet de fondu pour le chargement paresseux
            src={imageWebp}  // Utilisation de l'image .webp comme source par défaut
            srcSet={generateDynamicSrcSet(src, srcSetSizes)}  // Génération dynamique du srcSet
            sizes={sizes}  // Règle responsive pour déterminer quelle version d'image charger
            className={className}
            style={{ ...style, display: 'block', width: '100%', height: '100%' }}
            onError={(e) => {
                //console.log('srcSet',srcSet);
                //console.log('Erreur lors du chargement de l\'image optimisée .webp, retour à l\'image originale JPG:', src);
                e.target.onerror = null;  // Empêche la boucle d'erreur
                e.target.src = src;  // Fallback vers l'image originale si .webp échoue
            }}
            {...props}
        />
    );
};

export default OptimizedImage;
