import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import PwaIcon from '../assets/pwa-multiplatform-icon-final.svg';
import AnalyticsIcon from '../assets/data-analysis-icon-with-shadow.svg';
import VideoIcon from '../assets/video-surveillance-icon.svg';
import TrackingIcon from '../assets/adjusted-gps-tracking-icon.svg';
import AlibabaIcon from '../assets/alibaba-commerce-icon-centered.svg';
import ComputerIcon from '../assets/computer-laptop-sales-icon.svg';
import NetworkIcon from '../assets/satellite-internet-icon.svg';
import ContactIcon from '../assets/contact-icon-equal-height.svg';
import Home from '../assets/logo.png';

const menuItems = [
    { icon: PwaIcon, path: '/pwa', alt: 'Développement PWA pour Android et PC' },
    { icon: AnalyticsIcon, path: '/analytics', alt: 'Analyse de données' },
    { icon: VideoIcon, path: '/video', alt: 'Installation de caméras de surveillance' },
    { icon: TrackingIcon, path: '/tracking', alt: 'Installation et suivi GPS' },
    { icon: AlibabaIcon, path: '/alibaba', alt: 'Accompagnement Alibaba' },
    { icon: ComputerIcon, path: '/computer', alt: 'Vente et maintenance de PC' },
    { icon: NetworkIcon, path: '/network', alt: 'Connexion satellite avec Strat Link' },
    { icon: ContactIcon, path: '/contact', alt: 'Contactez-nous' },
];

const NavigationBar = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [visibleIcons, setVisibleIcons] = useState(menuItems.length);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 640) setVisibleIcons(2);
            else if (width < 768) setVisibleIcons(4);
            else if (width < 1024) setVisibleIcons(6);
            else setVisibleIcons(menuItems.length);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <nav className="fixed bottom-0 left-0 right-0 w-full  py-4 z-50">
            <div className="container mx-auto px-4 max-w-[90%] sm:max-w-[85%] md:max-w-[80%] lg:max-w-[75%] xl:max-w-4xl bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="h-16 flex items-center justify-between">
                    <Link to="/" className="flex items-center justify-center cursor-pointer transition-all duration-300 hover:scale-110">
                        <img src={Home} alt="Page d'accueil" className="w-[35px] h-[35px] object-contain rounded-lg" />
                    </Link>

                    <div className="flex items-center space-x-6">
                        {menuItems.slice(0, visibleIcons).map((item, index) => (
                            <Link
                                to={item.path}
                                key={index}
                                className="flex flex-col items-center justify-center cursor-pointer transition-all duration-300 hover:scale-110"
                            >
                                <img src={item.icon} alt={item.alt} className="w-16 h-16 sm:w-20 sm:h-20 object-contain" />
                            </Link>
                        ))}
                        {visibleIcons < menuItems.length && (
                            <button
                                onClick={() => setIsModalOpen(true)}
                                className="text-[#4285F4] hover:text-[#3367D6] transition-colors duration-300"
                            >
                                <Menu size={32} />
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {/* Elegant Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <div className="bg-white rounded-2xl p-6 w-full max-w-2xl shadow-2xl transform transition-all duration-300 ease-in-out">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold text-gray-800">Menu</h2>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="text-gray-500 hover:text-gray-700 transition-colors duration-300"
                            >
                                <X size={24} />
                            </button>
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6">
                            {menuItems.slice(visibleIcons).map((item, index) => (
                                <Link
                                    to={item.path}
                                    key={index}
                                    className="flex flex-col items-center justify-center p-4 cursor-pointer transition-all duration-300 hover:bg-gray-100 rounded-xl group"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    <div className="w-20 h-20 flex items-center justify-center mb-3 bg-blue-50 rounded-full group-hover:bg-blue-100 transition-colors duration-300">
                                        <img src={item.icon} alt={item.alt} className="w-16 h-16 object-contain" />
                                    </div>
                                    <span className="text-sm text-center text-gray-600 group-hover:text-gray-800 transition-colors duration-300">{item.alt}</span>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default NavigationBar;