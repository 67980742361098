import React, { useEffect, useRef } from 'react';

const AnimatedBackground = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        // Obtenir le ratio pixel de l'appareil
        const dpr = window.devicePixelRatio || 1;

        // Redimensionner le canvas en fonction du DPR
        const resizeCanvas = () => {
            const width = canvas.clientWidth;
            const height = canvas.clientHeight;
            canvas.width = width * dpr;
            canvas.height = height * dpr;
            ctx.scale(dpr, dpr);  // Mise à l'échelle pour garantir la netteté des traits et particules
        };

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();

        const colors = ['#34A853', '#EA4335', '#4285F4', '#FBBC05'];
        const particles = [];
        const particleCount = window.innerWidth > 768 ? 70 : 30; // Réduire les particules sur mobile
        const maxDistance = 100; // Distance maximale pour dessiner une ligne

        // Précalculer les dégradés pour éviter le recalcul à chaque frame
        const precomputedGradients = [];

        const getGradient = (particle1, particle2) => {
            const key = `${particle1.color}-${particle2.color}`;
            if (precomputedGradients[key]) return precomputedGradients[key];

            const gradient = ctx.createLinearGradient(particle1.x, particle1.y, particle2.x, particle2.y);
            gradient.addColorStop(0, particle1.color);
            gradient.addColorStop(1, particle2.color);
            precomputedGradients[key] = gradient;

            return gradient;
        };

        for (let i = 0; i < particleCount; i++) {
            particles.push({
                x: Math.random() * canvas.clientWidth,
                y: Math.random() * canvas.clientHeight,
                radius: Math.random() * 2 + 1,
                dx: (Math.random() - 0.5) * 0.5,
                dy: (Math.random() - 0.5) * 0.5,
                color: colors[Math.floor(Math.random() * colors.length)]
            });
        }

        let lastTime = 0;
        const fps = window.innerWidth > 768 ? 60 : 30; // Réduire les FPS sur mobile pour améliorer la fluidité
        const interval = 1000 / fps;

        const draw = (currentTime) => {
            if (currentTime - lastTime < interval) {
                animationFrameId = requestAnimationFrame(draw);
                return;
            }
            lastTime = currentTime;

            // Conserver le fond dynamique
            const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
            gradient.addColorStop(0, `hsl(${(Date.now() * 0.02) % 360}, 50%, 30%)`);
            gradient.addColorStop(1, `hsl(${(Date.now() * 0.02 + 60) % 360}, 50%, 40%)`);
            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Dessiner les particules et les lignes
            particles.forEach((particle, i) => {
                particle.x += particle.dx;
                particle.y += particle.dy;

                if (particle.x < 0 || particle.x > canvas.width) particle.dx = -particle.dx;
                if (particle.y < 0 || particle.y > canvas.height) particle.dy = -particle.dy;

                ctx.beginPath();
                ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
                ctx.fillStyle = particle.color;
                ctx.fill();

                // Ne créer des lignes que pour les particules proches
                for (let j = i + 1; j < particles.length; j++) {
                    const dx = particles[j].x - particle.x;
                    const dy = particles[j].y - particle.y;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    if (distance < maxDistance) {
                        ctx.beginPath();
                        ctx.moveTo(particle.x, particle.y);
                        ctx.lineTo(particles[j].x, particles[j].y);
                        ctx.strokeStyle = getGradient(particle, particles[j]);
                        ctx.lineWidth = 1;
                        ctx.stroke();
                    }
                }
            });

            animationFrameId = requestAnimationFrame(draw);
        };

        draw();

        return () => {
            cancelAnimationFrame(animationFrameId);
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    return <canvas ref={canvasRef} className="fixed inset-0 z-0" style={{ width: '100vw', height: '100vh' }} />;
};

export default AnimatedBackground;
