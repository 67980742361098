import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import OptimizedImage from "../../components/OptimizedImage";
import { Share2 } from 'lucide-react';
import NavigationBar from "../../components/NavigationBar";

const BlogListTemplate = () => {
    const location = useLocation();
    const blogs = location.state?.blogs || [];

    // Scroll to top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleShare = async (title, readMoreLink, coverImage) => {
        if (navigator.share) {
            try {
                const response = await fetch(coverImage);
                const blob = await response.blob();
                const file = new File([blob], 'image.jpg', { type: blob.type });

                const shareUrl = `${window.location.origin}${readMoreLink}?title=${encodeURIComponent(title)}`;

                await navigator.share({
                    title: title,
                    text: `Découvrez cet article: ${title}`,
                    url: shareUrl,
                    files: [file]
                });
                console.log('Article partagé avec succès!');
            } catch (error) {
                console.log('Erreur lors du partage avec image:', error);
                try {
                    const shareUrl = `${window.location.origin}${readMoreLink}?title=${encodeURIComponent(title)}`;
                    await navigator.share({
                        title: title,
                        text: `Découvrez cet article: ${title}`,
                        url: shareUrl
                    });
                    console.log('Article partagé sans image avec succès!');
                } catch (fallbackError) {
                    console.log('Erreur lors du partage sans image:', fallbackError);
                    alert('Le partage n\'est pas supporté sur cet appareil. Copiez ce lien: ' + `${window.location.origin}${readMoreLink}?title=${encodeURIComponent(title)}`);
                }
            }
        } else {
            alert('Le partage n\'est pas supporté sur cet appareil. Copiez ce lien: ' + `${window.location.origin}${readMoreLink}?title=${encodeURIComponent(title)}`);
        }
    };

    return (
        <div className="bg-gray-100 min-h-screen py-12 pb-32">
            <Helmet>
                <title>Liste des Blogs | Vytar</title>
                <meta name="description" content="Découvrez nos derniers articles sur divers sujets technologiques." />
                <meta property="og:title" content="Liste des Blogs | Vytar" />
                <meta property="og:description" content="Découvrez nos derniers articles sur divers sujets technologiques." />
                <meta property="og:image" content="/path-to-default-image.jpg" />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <NavigationBar />
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 pl-5 pr-5 pb-1 pt-5">
                {blogs.map(blog => (
                    <div key={blog.id} className="relative w-full h-[300px] bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-[1.02]">
                        <OptimizedImage
                            src={blog.coverImage}
                            alt={blog.title}
                            className="absolute inset-0 w-full h-full object-cover"
                            sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw"
                        />

                        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent"></div>

                        <div className="absolute bottom-0 left-0 p-3 text-white w-full">
                            <h2 className="text-lg font-bold mb-2 text-left line-clamp-2">{blog.title}</h2>

                            <div className="flex justify-between items-center">
                                <Link
                                    to={`/blog/${blog.id}`}
                                    className="bg-[#4285F4] text-white px-2 py-1 text-xs rounded-md font-medium hover:bg-opacity-80 transition-colors duration-300 ease-in-out hover:bg-[#3367D6]"
                                >
                                    En savoir plus
                                </Link>

                                {/*<button
                                    onClick={() =>  handleShare(blog.title, `/blog/${blog.id}`, blog.coverImage)}
                                    aria-label="Partager"
                                    className="bg-white text-[#4285F4] p-1 rounded-full hover:bg-opacity-80 transition-all duration-300 ease-in-out hover:rotate-12"
                                >
                                    <Share2 size={16} />
                                </button>*/}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogListTemplate;
