import React , {useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { blogs } from "../BlogData/BlogData";
import BlogReelCarousel from "../BlogReelCarousel";
import OptimizedImage from "../../components/OptimizedImage";
import { Calendar, User, Share2 } from 'lucide-react';
import NavigationBar from "../../components/NavigationBar";

const BlogContentTemplate = () => {
    // Scroll to top when the component mounts


    const { id } = useParams();
    console.log("Blog ID:", id);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);
    const blog = blogs.find(blog => blog.id === parseInt(id));

    if (!blog) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-2xl text-gray-600">Blog non trouvé.</p>
            </div>
        );
    }

    // Function to handle sharing
    const handleShare = async () => {
        if (navigator.share) {
            try {
                const response = await fetch(blog.coverImage);
                const blob = await response.blob();
                const file = new File([blob], 'image.jpg', { type: blob.type });

                const shareUrl = `${window.location.href}?title=${encodeURIComponent(blog.title)}`;

                await navigator.share({
                    title: blog.title,
                    text: `Découvrez cet article: ${blog.title}`,
                    url: shareUrl,
                    files: [file]
                });
                console.log('Article partagé avec succès!');
            } catch (error) {
                console.log('Erreur lors du partage avec image:', error);
                try {
                    const shareUrl = `${window.location.href}?title=${encodeURIComponent(blog.title)}`;
                    await navigator.share({
                        title: blog.title,
                        text: `Découvrez cet article: ${blog.title}`,
                        url: shareUrl
                    });
                    console.log('Article partagé sans image avec succès!');
                } catch (fallbackError) {
                    console.log('Erreur lors du partage sans image:', fallbackError);
                    alert('Le partage n\'est pas supporté sur cet appareil. Copiez ce lien: ' + `${window.location.href}?title=${encodeURIComponent(blog.title)}`);
                }
            }
        } else {
            alert('Le partage n\'est pas supporté sur cet appareil. Copiez ce lien: ' + `${window.location.href}?title=${encodeURIComponent(blog.title)}`);
        }
    };

    // Filtrer les articles du même service, exclure l'article en cours de lecture
    const relatedBlogs = blogs.filter(
        relatedBlog => relatedBlog.service === blog.service && relatedBlog.id !== blog.id
    );

    return (
        <div className="bg-gray-100 min-h-screen py-12 pb-32">
            <Helmet>
                <title>{blog.title} | Vytar Blog</title>
                <meta name="description" content={blog.contenu.substring(0, 160)} />
                <meta property="og:title" content={blog.title} />
                <meta property="og:description" content={blog.contenu.substring(0, 160)} />
                <meta property="og:image" content={blog.coverImage} />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <NavigationBar />
            <div className="container mx-auto px-4 max-w-4xl bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="relative mt-16">
                    <OptimizedImage
                        src={blog.coverImage}
                        alt={blog.title}
                        className="w-full h-96 object-cover"
                        sizes="(max-width: 1200px) 100vw, 1200px"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-40"></div>
                    <h1 className="absolute bottom-0 left-0 right-0 text-4xl font-bold text-white p-8 text-center">{blog.title}</h1>
                </div>

                <div className="px-8 py-6">
                    <div className="flex items-center justify-between text-sm text-gray-600 mb-8">
                        <div className="flex items-center">
                            <User size={18} className="mr-2" />
                            <span>Par {blog.auteur || 'Auteur inconnu'}</span>
                        </div>
                        <div className="flex items-center">
                            <Calendar size={18} className="mr-2" />
                            <span>{blog.date || 'Date non spécifiée'}</span>
                        </div>
                        <div className="flex items-center">
                            <Share2 size={18} className="mr-2"/>
                            <button onClick={handleShare} className="text-[#4285F4]">
                                Partager
                            </button>
                        </div>
                    </div>

                    <div className="prose prose-lg max-w-none mx-auto text-gray-800 leading-relaxed space-y-8 text-left">
                        <div
                            dangerouslySetInnerHTML={{ __html: blog.contenu }}
                            className="[&>p]:mb-8"
                        />
                    </div>
                </div>

                {/* Section pour afficher les articles similaires */}
                <div className="mt-12 px-8">

                </div>

            </div>
            <BlogReelCarousel blogs={relatedBlogs} spacing={4} />
        </div>
    );
};

export default BlogContentTemplate;
