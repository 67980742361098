import React, { useRef, useEffect, useState } from 'react';

const DynamicBackground = ({ speed = 0.0005 }) => {
    const canvasRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(window.innerWidth < 768);
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return; // Vérification initiale

        const ctx = canvas.getContext('2d');

        const resizeCanvas = () => {
            if (canvas) { // Vérification supplémentaire
                canvas.width = canvas.clientWidth;
                canvas.height = canvas.clientHeight;
            }
        };

        resizeCanvas();

        let lastUpdateTime = 0;
        const updateInterval = isMobile ? 100 : 50;

        const animateBackground = (currentTime) => {
            if (currentTime - lastUpdateTime > updateInterval) {
                const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
                const time = Date.now() * 0.02;

                gradient.addColorStop(0, `hsl(${time % 360}, 50%, 30%)`);
                gradient.addColorStop(1, `hsl(${(time + 60) % 360}, 50%, 40%)`);

                ctx.fillStyle = gradient;
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                lastUpdateTime = currentTime;
            }

            animationId = requestAnimationFrame(animateBackground);
        };

        let animationId = requestAnimationFrame(animateBackground);

        const handleResize = () => {
            resizeCanvas();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            cancelAnimationFrame(animationId);
            window.removeEventListener('resize', handleResize);
            // Pas besoin de supprimer le canvas manuellement ici
        };
    }, [speed, isMobile]);

    return <canvas ref={canvasRef} className="absolute inset-0 w-full h-full" />;
};

export default DynamicBackground;